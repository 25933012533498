import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'react-oidc-context';

import { request } from '@/services';
import { authClientId } from '@/services/auth';

import { endpoints } from './endpoints';
import { walletConfigQueryKeys } from './wallet-config-query-keys';

import type { WalletConfigOpenTypes } from '../types/wallet-config-open-types';
import type { UseQueryOptions } from '@tanstack/react-query';

export function useGetWalletConfigOpen(
  options?: UseQueryOptions<WalletConfigOpenTypes.TWalletConfigOpen>
) {
  const auth = useAuth();

  const getWalletConfigOpenRequest = async () =>
    await request<WalletConfigOpenTypes.TWalletConfigOpen>(
      endpoints.WALLET_CONFIGURATION_OPEN,
      auth
    );

  return useQuery({
    enabled: auth.isAuthenticated && !!authClientId,
    queryFn: getWalletConfigOpenRequest,
    queryKey: walletConfigQueryKeys.getWalletConfigOpen,
    ...options,
  });
}

import { Divider } from '@b707/ponyta';

import { ButtonSpinner, SecurityAssurance } from '@/components';
import { useCardStore } from '@/store';

import styles from './add-card-form-footer.module.css';

export function AddCardFormFooter() {
  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const isAddCardPending = useCardStore().use.isAddCardPending();

  return (
    <div className={styles['add-card-form-footer']}>
      {!is3dsIframeOpen && <SecurityAssurance />}

      <Divider />

      <ButtonSpinner
        disabled={isAddCardPending}
        level="primary"
        loading={isAddCardPending}
        size="medium"
        spinnerColor={'var(--colors-onSurfaceC)'}
        type="submit"
        variant="branded"
      >
        Save
      </ButtonSpinner>
    </div>
  );
}

import { useCardStore } from '@/store';

import { EditCardNicknameButton } from '../edit-card-nickname-button/edit-card-nickname-button';

import styles from './edit-card-nickname.module.css';

export function EditCardNickname() {
  const selectedCard = useCardStore().use.selectedCard();
  const cardName = selectedCard?.alias || selectedCard?.data.issuer_name || '-';

  return (
    <div className={styles['card-details-column']} role="listitem">
      <div className={styles['nicknames-column']}>
        <div>
          <div className={styles['card-details-column-label']}>
            Card nickname
          </div>
          <p className={styles['card-details-column-text']}>{cardName}</p>
        </div>
        <div>
          {!selectedCard?.is_validated && !selectedCard?.is_invalid && (
            <EditCardNicknameButton />
          )}
        </div>
      </div>
    </div>
  );
}

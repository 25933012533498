export enum CardDialogSuccessStates {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  SET_DEFAULT = 'set default',
  UPDATE_NICKNAME = 'update nickname',
}

export enum CardDialogErrorStates {
  BANK_CARD_ADD_ERROR = 'BANK_CARD_ADD_ERROR',
  BANK_CARD_COUNT_LIMIT_REACHED = 'BANK_CARD_COUNT_LIMIT_REACHED',
  BANK_CARD_DELETE_ERROR = 'BANK_CARD_DELETE_ERROR',
  BANK_CARD_EDIT_ERROR = 'BANK_CARD_EDIT_ERROR',
  BANK_CARD_EDIT_NICKNAME_ERROR = 'BANK_CARD_EDIT_NICKNAME_ERROR',
  BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR = 'BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR',
  BANK_CARD_SET_DEFAULT_ERROR = 'BANK_CARD_SET_DEFAULT_ERROR',
  GIFT_CARD_COUNT_LIMIT_REACHED = 'GIFT_CARD_COUNT_LIMIT_REACHED',
  NULL = 'NULL',
}

import { LogoCard } from '@b707/ponyta';

import { Breakpoints } from '@/config/breakpoints';
import { useWindowSize } from '@/hooks';

import { Styled } from '../../styles';

import type { LegacyRef } from 'react';
interface IMycolesProps {
  buttonRef: LegacyRef<HTMLButtonElement>;
  handleOpenViewCard: () => void;
}

export function MycolesLinked({
  buttonRef,
  handleOpenViewCard,
}: IMycolesProps) {
  const windowSize = useWindowSize();
  const myColesCardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xl ? 'md' : 'sm';

  return (
    <Styled.LoyaltyCardItemContainerButton
      onClick={handleOpenViewCard}
      ref={buttonRef}
      tabIndex={0}
    >
      <LogoCard size={myColesCardSize} type="mycoles" />
      <Styled.LoyaltyCardItemDetails>
        <Styled.LoyaltyCardItemTitle>
          mycoles discount card
        </Styled.LoyaltyCardItemTitle>
        Membership
      </Styled.LoyaltyCardItemDetails>
    </Styled.LoyaltyCardItemContainerButton>
  );
}

import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '@/features/card-management';

import { useDeleteCardRequest } from './use-delete-card-request';

import type { MutationOptions } from '@tanstack/react-query';

export function useDeleteCard(cardId: string, options?: MutationOptions) {
  return useMutation({
    mutationFn: useDeleteCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankDelete,
    ...options,
  });
}

import { useId } from 'react';

export function EftposLogoIcon({ height = 24 }) {
  const id = useId();
  const clipId = useId();
  const paintId = useId();

  const width = `${height * (92 / 32)}`;
  return (
    <svg
      aria-labelledby={id}
      fill="none"
      height={height}
      role="img"
      viewBox="0 0 92 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text id={id}>eftpos</text>
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M17.7913 5.38628C13.4415 7.36406 10.5003 11.1535 10.5003 15.5042C10.5003 20.9939 15.575 25.4437 21.8375 25.4437C24.7115 25.4437 26.732 24.7824 29.0944 23.3669C29.346 23.2173 29.6277 23.1038 29.8141 23.338C29.9177 23.4731 29.8752 23.6351 29.7945 23.7847C27.2136 28.5006 21.9629 31.7804 16.07 31.7804C12.8042 31.7812 9.61882 30.7718 6.95372 28.8915C4.28863 27.0113 2.2754 24.353 1.1921 21.2839C0.108797 18.2148 0.00889632 14.8863 0.906195 11.7582C1.80349 8.63005 3.65371 5.8567 6.20128 3.82119C8.89915 1.66286 15.6744 1.36366 17.7913 5.38628Z"
          fill={`url(#${paintId})`}
        />
        <path
          d="M29.6122 11.4681C29.6122 16.6844 24.61 20.9133 18.4406 20.9133C17.5427 20.9133 15.112 20.7906 13.3721 19.9848C12.8771 19.7558 12.9289 19.4689 13.2686 19.3276C14.0588 19.0026 20.2592 17.8172 20.2592 11.4691C20.2592 5.11997 13.6797 0.478327 6.20126 3.82621C8.99729 1.58355 12.4803 0.363003 16.07 0.367935C23.392 0.367935 29.6122 5.33766 29.6122 11.4681Z"
          fill="#E50056"
        />
        <path
          d="M40.8355 11.8323C37.4376 11.8323 35.619 13.8678 35.619 16.5038V16.5792C35.619 19.2296 37.0616 20.966 40.6843 20.966C43.3004 20.966 45.0589 20.1282 45.8108 18.1515H43.2828C42.9079 18.7953 42.2005 19.1543 40.848 19.1543C38.9993 19.1543 38.0817 18.4806 37.8446 17.2529H45.932V16.3388C45.9309 13.7337 44.1724 11.8323 40.8355 11.8323ZM37.8736 15.5464C38.1739 14.3331 39.1661 13.6295 40.877 13.6295C42.5454 13.6295 43.4019 14.2434 43.6277 15.5464H37.8736Z"
          fill="white"
        />
        <path
          d="M56.1373 9.76595H54.183C54.1902 10.2415 54.1601 10.7168 54.0929 11.1876C53.9583 11.9098 53.7822 12.0584 53.1308 12.1162C52.7258 12.1461 52.2743 12.1461 51.8383 12.1461H50.3055V11.785C50.3055 10.482 50.5613 10.2374 51.7037 10.2374H52.4607V8.38037H51.4479C48.9519 8.38037 48.0954 9.21915 48.0954 11.6901V12.1409H46.0666V14.0898H48.0954V20.7093H50.29L50.3055 14.0898H53.9427V16.7857C53.9427 19.7054 54.7247 20.7062 57.5955 20.7062H59.2318V18.6603H58.1962C56.4221 18.6603 56.1362 18.4055 56.1362 16.4143V14.0898H59.2318V12.1482H56.1352L56.1373 9.76595Z"
          fill="white"
        />
        <path
          d="M66.1033 11.8623C64.4204 11.8623 63.1269 12.5505 62.4661 13.7338L62.2745 12.1481H60.3068V23.7517H62.5759V19.3515C63.1973 20.3698 64.32 20.9382 66.0485 20.9382C68.4843 20.9382 70.5018 19.4711 70.5018 16.5215V16.4462C70.4935 13.4645 68.8095 11.8623 66.1033 11.8623ZM68.1332 16.505C68.1332 18.1371 67.2757 19.0358 65.3225 19.0358C63.4728 19.0358 62.5262 18.1227 62.5262 16.4905V16.4297C62.5262 14.7377 63.5929 13.7349 65.3525 13.7349C67.2011 13.7349 68.1332 14.6634 68.1332 16.4451V16.505Z"
          fill="white"
        />
        <path
          d="M76.551 11.8323C73.4689 11.8323 71.38 13.5542 71.38 16.4285V16.5193C71.38 19.3194 72.9335 20.966 76.5054 20.966C79.978 20.966 81.7365 19.2441 81.7365 16.5193V16.4285C81.7365 13.5243 79.6921 11.8323 76.551 11.8323ZM79.3773 16.5038C79.3773 18.136 78.5198 19.0346 76.551 19.0346C74.6568 19.0346 73.7402 18.1515 73.7402 16.5038V16.4285C73.7402 14.6622 74.7624 13.7337 76.581 13.7337C78.4452 13.7337 79.3773 14.6922 79.3773 16.4285V16.5038Z"
          fill="white"
        />
        <path
          d="M87.7195 15.4711C85.4794 15.3205 84.8631 15.1709 84.8631 14.5426C84.8631 14.0185 85.2991 13.5686 86.9075 13.5686C88.3212 13.5686 89.1932 13.8988 89.3879 14.752H91.8548C91.3888 12.8196 89.855 11.8323 86.9386 11.8323C84.0222 11.8323 82.6096 13.06 82.6096 14.7211C82.6096 16.5028 84.1133 17.0723 86.7884 17.2219C89.013 17.3416 89.7048 17.4757 89.7048 18.1205C89.7048 18.7488 89.1642 19.1388 87.5403 19.1388C85.8719 19.1388 85.12 18.7788 84.8797 17.8657H82.2626C82.6386 19.9291 84.2625 20.9608 87.4492 20.9608C90.4702 20.9608 91.9884 19.7331 91.9884 18.0266C91.9884 16.1892 90.6959 15.6506 87.7195 15.4711Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={paintId}
          x1="15.228"
          x2="9.23853"
          y1="21.2508"
          y2="-9.67694"
        >
          <stop offset="0.14" stopColor="#E50056" />
          <stop offset="0.75" stopColor="#1F0038" />
        </linearGradient>
        <clipPath id={clipId}>
          <rect
            fill="white"
            height="32"
            transform="translate(0.292694)"
            width="91.7073"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export const iconHeights = {
  amex: {
    lg: 87,
    md: 87,
    sm: 64,
    xs: 32,
  },
  default: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
  },
  eftpos: {
    lg: 32,
    md: 22,
    sm: 62,
    xs: 34,
  },
  mastercard: {
    lg: 48,
    md: 30,
    sm: 30,
    xs: 15,
  },
  visa: {
    lg: 29,
    md: 18,
    sm: 18,
    xs: 10,
  },
};

import { Divider } from '@b707/ponyta';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ButtonSpinner, Checkbox, ErrorDialog, Form } from '@/components';
import { genericContent } from '@/config/language';
import { ERROR_ADD_ADDRESS_GENERIC } from '@/config/language/errors';
import { useAddAddress } from '@/features/profile/services';
import { validate } from '@/utils';

import { AddressInput, AddressInputFieldNames } from '../address-input';

import { Styled } from './styles';

import type { FieldValues } from 'react-hook-form';

interface IProps {
  isAddAddressOpen: boolean;
  reloadAddresses: () => void;
}

const DEFAULT_FORM_VALUES = {
  [AddressInputFieldNames.ADDRESS_NICKNAME]: '',
  [AddressInputFieldNames.ADDRESS_NAME]: '',
  [AddressInputFieldNames.ADDRESS_LINE_1]: '',
  [AddressInputFieldNames.ADDRESS_LINE_2]: '',
  [AddressInputFieldNames.ADDRESS_CITY]: '',
  [AddressInputFieldNames.ADDRESS_STATE]: '',
  [AddressInputFieldNames.ADDRESS_POSTCODE]: '',
  [AddressInputFieldNames.ADDRESS_IS_DEFAULT]: false,
} as FieldValues;

const validationSchema = Joi.object({
  [AddressInputFieldNames.ADDRESS_NICKNAME]: validate.addressNickname(),
  [AddressInputFieldNames.ADDRESS_NAME]: validate.name(),
  [AddressInputFieldNames.ADDRESS_LINE_1]: validate.addressLine1(),
  [AddressInputFieldNames.ADDRESS_LINE_2]: validate.addressLine2(),
  [AddressInputFieldNames.ADDRESS_CITY]: validate.addressCity(),
  [AddressInputFieldNames.ADDRESS_STATE]: validate.addressState(),
  [AddressInputFieldNames.ADDRESS_POSTCODE]: validate.addressPostcode(),
}).unknown(true);

export const AddAddress = ({ isAddAddressOpen, reloadAddresses }: IProps) => {
  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });

  useEffect(() => {
    formMethods.reset(DEFAULT_FORM_VALUES);
  }, [formMethods, isAddAddressOpen]);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const addNewAddress = useAddAddress({
    onError: () => {
      setIsDialogOpen(true);
    },
    onSuccess: () => {
      reloadAddresses();
    },
  });

  const onSubmitForm = async ({
    addressCity,
    addressIsDefault,
    addressLine1,
    addressLine2,
    addressName,
    addressNickname,
    addressPostcode,
    addressState,
  }: FieldValues) => {
    addNewAddress.mutate({
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      country_code: 'AU',
      is_shipping: addressIsDefault,
      nickname: addressNickname,
      postcode: addressPostcode,
      recipient_name: addressName,
      state: addressState,
      suburb: addressCity,
    });
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isSubmitting = addNewAddress.isPending;

  return (
    <Styled.Container>
      <Form config={formMethods} onSubmitForm={onSubmitForm}>
        <div>
          {isAddAddressOpen && <AddressInput isSubmitting={isSubmitting} />}

          <Checkbox
            id={AddressInputFieldNames.ADDRESS_IS_DEFAULT}
            label="Set as default address"
            {...formMethods.register(
              `${AddressInputFieldNames.ADDRESS_IS_DEFAULT}`
            )}
          />
        </div>

        <Styled.Footer>
          <Divider />
          <ButtonSpinner
            disabled={isSubmitting}
            level="primary"
            loading={isSubmitting}
            size="medium"
            spinnerColor={'var(--colors-onSurfaceC)'}
            type="submit"
            variant="branded"
          >
            Save
          </ButtonSpinner>
        </Styled.Footer>
      </Form>
      <ErrorDialog
        isOpen={isDialogOpen}
        message={ERROR_ADD_ADDRESS_GENERIC}
        onOpenChange={onCloseDialog}
        title={genericContent.ERROR_GENERIC_TITLE}
      />
    </Styled.Container>
  );
};

import styled from 'styled-components';

import { Button } from '@/components';

const AddBtn = styled(Button)`
  background: var(--colors-brand1Lowest);
  border: none;
  margin-top: -0.25rem;
`;

export const Styled = {
  AddBtn,
};

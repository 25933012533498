import { ExclamationMarkIcon } from '@/components/icons';
import {
  ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT,
  TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
} from '@/config/language/errors';
import { CardDialogErrorStates, useCardStore } from '@/store';

export const errorsGiftCards = {
  [CardDialogErrorStates.GIFT_CARD_COUNT_LIMIT_REACHED]() {
    const setErrorDialogState =
      useCardStore().getState().actions.setErrorDialogState;

    return {
      buttonText: 'Continue',
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: <ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT />,
      onOpenChange() {
        setErrorDialogState(CardDialogErrorStates.NULL);
      },
      title: TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
      tryAgainMessage: '',
    };
  },
};

import { clsx } from 'clsx';

import { AmexLogoIcon } from '../icons/amex-logo-icon';
import { AmexLogoSmallIcon } from '../icons/amex-logo-small-icon';
import { EftposLogoIcon } from '../icons/eftpos-logo-icon';
import { EftposLogoSmallIcon } from '../icons/eftpos-logo-small-icon';
import { MastercardLogoIcon } from '../icons/mastercard-logo-icon';
import { VisaLogoIcon } from '../icons/visa-logo-icon';

import { getDisplayScheme } from './get-display-scheme';
import { iconHeights } from './icon-heights';
import { PaymentCardSchemes } from './payment-card-enums';
import styles from './payment-card.module.css';

import type { IPaymentCardProps } from './payment-card-types';

const schemeIcons = {
  [PaymentCardSchemes.AMEX]: AmexLogoIcon,
  [PaymentCardSchemes.DEFAULT]: undefined,
  [PaymentCardSchemes.EFTPOS]: EftposLogoIcon,
  [PaymentCardSchemes.MASTERCARD]: MastercardLogoIcon,
  [PaymentCardSchemes.VISA]: VisaLogoIcon,
};

const schemeIconsSmall = {
  [PaymentCardSchemes.AMEX]: AmexLogoSmallIcon,
  [PaymentCardSchemes.DEFAULT]: undefined,
  [PaymentCardSchemes.EFTPOS]: EftposLogoSmallIcon,
  [PaymentCardSchemes.MASTERCARD]: MastercardLogoIcon,
  [PaymentCardSchemes.VISA]: VisaLogoIcon,
};

export function PaymentCard({
  alias,
  cardType,
  issuer,
  lastFour,
  schemes = ['default'],
  size = 'xs',
}: IPaymentCardProps) {
  const displayScheme = getDisplayScheme(schemes);
  const shouldShowIssuer = !alias || alias.trim() === '';
  const shouldShowCardType = displayScheme !== 'amex';

  const cardTitle = shouldShowIssuer ? issuer : alias;

  const SchemeIcon = schemes && schemeIcons[`${displayScheme}`];
  const SchemeIconSmall = schemes && schemeIconsSmall[`${displayScheme}`];
  const iconHeight = schemes && iconHeights[`${displayScheme}`][`${size}`];

  return (
    <div
      className={clsx(
        styles.card,
        styles[`card-${size}`],
        styles[`card-${displayScheme}`]
      )}
    >
      <div
        className={clsx(styles['card-content'], styles.logo, {
          [styles['card-content-simple']]: size !== 'lg',
        })}
      >
        {(size === 'xs' || size === 'sm') && SchemeIconSmall && (
          <SchemeIconSmall height={iconHeight} />
        )}

        {size === 'md' && (
          <>
            {SchemeIcon && <SchemeIcon height={iconHeight} />}

            {cardType && shouldShowCardType && (
              <p
                className={clsx(styles['card-text'], styles['spaced-text'])}
                style={{ fontSize: 'var(--fontSizes-12)' }}
              >
                {cardType?.toUpperCase()}
              </p>
            )}
          </>
        )}

        {size === 'lg' && (
          <>
            <div className={styles['card-lhs']}>
              <p
                className={styles['card-text']}
                style={{ marginBottom: 'var(--space-xs)' }}
              >
                {cardTitle}
              </p>

              {lastFour && (
                <p className={clsx(styles['card-text'], styles['spaced-text'])}>
                  ••• {lastFour}
                </p>
              )}
            </div>

            <div className={styles['card-rhs']}>
              {SchemeIcon && <SchemeIcon height={iconHeight} />}

              {cardType && shouldShowCardType && (
                <p
                  className={clsx(styles['card-text'], styles['spaced-text'])}
                  style={{ fontSize: 'var(--fontSizes-14)' }}
                >
                  {cardType?.toUpperCase()}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

import { useState } from 'react';

import { Button, ErrorDialog, Spinner, SuccessDialog } from '@/components';
import { ExclamationMarkTriangleIcon } from '@/components/icons';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_DELETE_PROFILE,
  ERROR_MARKETING_SUBSCRIBE,
  ERROR_MARKETING_UNSUBSCRIBE,
  ERROR_TITLE_DELETE_PROFILE,
  ERROR_TITLE_SUBSCRIBE,
  ERROR_TITLE_UNSUBSCRIBE,
} from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  getMarketingConsent,
  hasConsentedToMarketing,
  useGetProfile,
  useMarketingUnsubscribe,
  useUpdateProfile,
} from '@/features/profile';
import { useAnalytics } from '@/hooks';
import { toNationalPhoneNumber } from '@/utils';

import styles from './account.module.css';
import { DeleteDialogBody } from './delete-dialog';
import { Styled } from './styles';
import { UpdateEmailSheet } from './update-email-sheet';
import { UpdateMobileSheet } from './update-mobile-sheet';

export const AccountSettings = () => {
  const { data: user, isLoading, refetch } = useGetProfile();
  const { theme } = useTheme();
  const brand = theme.brand.title;
  const { track } = useAnalytics();

  const [isOpenUpdateEmail, setIsOpenUpdateEmail] = useState(false);
  const [isOpenUpdateMobile, setIsOpenUpdateMobile] = useState(false);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [isSubscribeErrorDialogOpen, setIsSubscribeErrorDialogOpen] =
    useState(false);
  const [isUnsubscribeErrorDialogOpen, setIsUnsubscribeErrorDialogOpen] =
    useState(false);
  const [isSubscribeSuccessDialogOpen, setIsSubscribeSuccessDialogOpen] =
    useState(false);
  const [isUnsubscribeSuccessDialogOpen, setIsUnsubscribeSuccessDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteErrorDialogOpen, setIsDeleteErrorDialogOpen] = useState(false);

  const handleIsOpenUpdateEmailOpen = () => {
    track('Update Email Started');
    setIsOpenUpdateEmail(true);
  };

  const handleIsOpenUpdateEmailClose = () => {
    setIsOpenUpdateEmail(false);
  };

  const handleIsOpenUpdateMobileOpen = () => {
    track('Update Mobile Started');
    setIsOpenUpdateMobile(true);
  };

  const handleIsOpenUpdateMobileClose = () => {
    setIsOpenUpdateMobile(false);
  };

  const reload = async () => {
    setIsOpenUpdateEmail(false);
    setIsOpenUpdateMobile(false);
    await refetch();
  };

  const isSubscribedToMarketing = hasConsentedToMarketing(user);

  const updateProfile = useUpdateProfile({
    onError: () => {
      setIsSubscribeErrorDialogOpen(true);
    },
    onSettled: () => {
      setIsSubscribeLoading(false);
    },
    onSuccess: () => {
      setIsSubscribeSuccessDialogOpen(true);
      setIsSubscribeLoading(false);
      refetch();
    },
  });

  const marketingUnsubscribe = useMarketingUnsubscribe({
    onError: () => {
      setIsUnsubscribeErrorDialogOpen(true);
    },
    onSettled: () => {
      setIsSubscribeLoading(false);
    },
    onSuccess: () => {
      refetch();
      setIsUnsubscribeSuccessDialogOpen(true);
    },
  });

  const toggleMarketing = () => {
    setIsSubscribeLoading(true);

    if (isSubscribedToMarketing) {
      track('Marketing Unsubscribe');
      if (user?.current_wallet_id) {
        marketingUnsubscribe.mutate({
          user_id: user.id,
          wallet_id: user.current_wallet_id,
        });
      } else {
        setIsSubscribeLoading(false);
        setIsUnsubscribeErrorDialogOpen(true);
      }
    } else {
      track('Marketing Subscribe');
      const consent = getMarketingConsent(user, true);
      if (consent) {
        updateProfile.mutate({
          consents: [consent],
        });
      } else {
        setIsSubscribeLoading(false);
        setIsSubscribeErrorDialogOpen(true);
      }
    }
  };

  const onDeleteErrorClose = () => {
    setIsDeleteErrorDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  return (
    <Styled.Panel>
      <Styled.PanelHeader>
        <Styled.PanelTitle>Account</Styled.PanelTitle>
      </Styled.PanelHeader>
      <Styled.PanelContent>
        <Styled.Row>
          <Styled.DisplayNameWrapper>
            <Styled.DisplayNameHeader>Email</Styled.DisplayNameHeader>
            <Styled.DisplayName>{user?.email}</Styled.DisplayName>
          </Styled.DisplayNameWrapper>
          <Button
            aria-label="Change email"
            level="tertiary"
            onClick={handleIsOpenUpdateEmailOpen}
            variant="neutral"
          >
            Change
          </Button>
        </Styled.Row>
        <Styled.Row>
          <Styled.DisplayNameWrapper>
            <Styled.DisplayNameHeader>Mobile</Styled.DisplayNameHeader>
            <Styled.DisplayName>
              {user?.mobile_number && toNationalPhoneNumber(user.mobile_number)}
            </Styled.DisplayName>
          </Styled.DisplayNameWrapper>
          <Button
            aria-label="Change mobile"
            level="tertiary"
            onClick={handleIsOpenUpdateMobileOpen}
            variant="neutral"
          >
            Change
          </Button>
        </Styled.Row>
        <Styled.Row>
          <Styled.DisplayNameWrapper>
            <Styled.DisplayNameHeader>
              Subscribe to flypay marketing emails
            </Styled.DisplayNameHeader>
            <Styled.DisplayName>
              {isSubscribedToMarketing ? 'Subscribed' : 'Not subscribed'}
            </Styled.DisplayName>
          </Styled.DisplayNameWrapper>
          <Button
            aria-label={
              isSubscribedToMarketing
                ? 'Unsubscribe from marketing material'
                : 'Subscribe to marketing material'
            }
            className={styles['subscribe-button']}
            level="tertiary"
            onClick={toggleMarketing}
          >
            {(isSubscribeLoading || isLoading) && <Spinner size={12} />}
            {!isSubscribeLoading &&
              !isLoading &&
              (isSubscribedToMarketing ? 'Unsubscribe' : 'Subscribe')}
          </Button>
        </Styled.Row>
        <Styled.Row>
          <Styled.DisplayNameWrapper>
            <Styled.DisplayNameHeader>
              Delete my account
            </Styled.DisplayNameHeader>
            <Styled.DisplayName>
              Deletes all your flypay account data and history
            </Styled.DisplayName>
          </Styled.DisplayNameWrapper>
          <Styled.DeleteDialog
            body={
              <DeleteDialogBody
                closeDialog={() => setIsDeleteDialogOpen(false)}
                setIsErrorDialogOpen={setIsDeleteErrorDialogOpen}
              />
            }
            onOpenChange={setIsDeleteDialogOpen}
            open={isDeleteDialogOpen}
            trigger={
              <Button
                level="tertiary"
                onClick={() => track('Delete Account Started')}
                variant="destructive"
              >
                Delete account
              </Button>
            }
            triggerIsButton
          />
        </Styled.Row>
      </Styled.PanelContent>

      <SuccessDialog
        buttonText="Back to my account"
        description={`Way to stay in the loop about all things ${brand}!`}
        isOpen={isSubscribeSuccessDialogOpen}
        onOpenChange={() => setIsSubscribeSuccessDialogOpen(false)}
        title={'You’re subscribed'}
      />

      <SuccessDialog
        buttonText="Back to my account"
        description={`No more marketing messages or emails!`}
        isOpen={isUnsubscribeSuccessDialogOpen}
        onOpenChange={() => setIsUnsubscribeSuccessDialogOpen(false)}
        title={'You’ve unsubscribed'}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
        isOpen={isSubscribeErrorDialogOpen}
        message={ERROR_MARKETING_SUBSCRIBE}
        onOpenChange={() => setIsSubscribeErrorDialogOpen(false)}
        title={ERROR_TITLE_SUBSCRIBE}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
        isOpen={isUnsubscribeErrorDialogOpen}
        message={ERROR_MARKETING_UNSUBSCRIBE}
        onOpenChange={() => setIsUnsubscribeErrorDialogOpen(false)}
        title={ERROR_TITLE_UNSUBSCRIBE}
      />

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        icon={<ExclamationMarkTriangleIcon color="var(--colors-errorHigh)" />}
        isOpen={isDeleteErrorDialogOpen}
        message={ERROR_DELETE_PROFILE}
        onOpenChange={onDeleteErrorClose}
        title={ERROR_TITLE_DELETE_PROFILE}
      />

      <UpdateEmailSheet
        onClose={handleIsOpenUpdateEmailClose}
        open={isOpenUpdateEmail}
        reload={reload}
      />

      <UpdateMobileSheet
        onClose={handleIsOpenUpdateMobileClose}
        open={isOpenUpdateMobile}
        reload={reload}
      />
    </Styled.Panel>
  );
};

import clsx from 'clsx';

import { ChevronRightIcon } from '@/components/icons';
import { MerchantLogo } from '@/features/activity';
import { useBreakpoints } from '@/hooks';
import { formatCurrency } from '@/utils/format/currency';
import { Keys } from '@/utils/keyboard';

import { OrderRowRefund } from './order-row-refund';
import styles from './order-row.module.css';

import type { OrderTypes } from '../../types';
import type { KeyboardEvent } from 'react';

export type TOrderRowProps = {
  onClick?: (id: string) => void;
  order: OrderTypes.TOrderInfo;
};

export function OrderRow({ onClick, order }: TOrderRowProps) {
  const breakpoints = useBreakpoints();

  const {
    amount,
    created_at,
    hasRefund,
    merchantId,
    merchantName,
    orderId,
    orderReference,
    payments,
  } = order;

  const onClickOrder = () => {
    onClick?.(orderId);
  };

  const handleMobileClick = () => {
    if (!breakpoints.md) {
      onClick?.(orderId);
    }
  };

  const handleMobileEnter = (e: KeyboardEvent) => {
    if (!breakpoints.md && e.key === Keys.ENTER) {
      onClick?.(orderId);
    }
  };

  const createdAtDate = !!created_at?.dayMonthYear
    ? created_at.dayMonthYear
    : '-';

  const orderStyles = clsx(styles['order'], hasRefund && styles['has-refund']);

  const orderDataStyles = clsx(styles['order-data'], styles['mobile-hidden']);

  const orderReferenceStyles = clsx(
    styles['order-data'],
    styles['mobile-hidden'],
    styles['order-reference']
  );

  const viewDetailStyles = clsx(
    styles['order-data'],
    styles['is-last'],
    styles['mobile-hidden']
  );

  const currencyStyles = clsx(
    styles['order-data'],
    styles['mobile-small-font']
  );

  const displayedOrderId = orderReference ?? orderId;

  const hasRefunds = hasRefund && !!payments;

  const orderAmount = formatCurrency(amount);

  return (
    <div
      className={styles['wrapper']}
      onClick={handleMobileClick}
      onKeyDown={handleMobileEnter}
      {...(!breakpoints.md && { role: 'button', tabIndex: 0 })}
    >
      <div className={orderStyles}>
        <div className={orderDataStyles}>{createdAtDate}</div>
        <div className={styles['order-data']}>
          <div className={styles['merchant-details']}>
            <MerchantLogo merchantId={merchantId} merchantName={merchantName} />
            <div className={styles['merchant-data']}>
              <span className={styles['merchant-name']}>{merchantName}</span>
              <span className={styles['order-id']}>{displayedOrderId}</span>
            </div>
          </div>
        </div>
        <div className={orderReferenceStyles}>{orderReference ?? orderId}</div>
        <div className={currencyStyles}>{orderAmount}</div>
        <div className={viewDetailStyles}>
          <button className={styles['ghost-button']} onClick={onClickOrder}>
            View detail <ChevronRightIcon />
          </button>
        </div>
      </div>
      {hasRefunds && (
        <div className={styles['refunds']}>
          <div className={styles['divider']} />
          {payments.map((payment, i) => {
            const isLast = i === payments.length - 1;
            return (
              <OrderRowRefund
                isLast={isLast}
                key={payment.id}
                payment={payment}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

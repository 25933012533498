import { useEffect, useId, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';

import { Spinner } from '@/components';
import { CloseIcon } from '@/components/icons';
import { useDisableScroll } from '@/hooks';
import { useSetA11yActions } from '@/store';

import { Styled } from './styles';
interface IProps {
  children?: React.ReactNode;
  loading?: boolean;
  onClose?: () => void;
  open?: boolean;
  /**
   * When refocus state changes refocus on side sheet's first focusuable element
   */
  refocusState?: string;
  subtitle?: string;
  title: string;
}

let timeout: NodeJS.Timeout;

export const SideSheet = ({
  children,
  loading,
  onClose,
  open,
  refocusState,
  subtitle,
  title,
}: IProps) => {
  const [animationCanPlay, setAnimationCanPlay] = useState<boolean>();
  const [isOpen, setIsOpen] = useState<boolean>();

  const titleId = useId();
  const { setA11yAnnouncement } = useSetA11yActions();

  useDisableScroll(isOpen);

  useEffect(() => {
    if ((!!isOpen && !animationCanPlay) || open) {
      setAnimationCanPlay(true);
    }
    setIsOpen(open);
  }, [animationCanPlay, isOpen, open]);

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    onClose?.();
    setA11yAnnouncement('Dialog closed');
  };

  useEffect(() => {
    if (refocusState || open) {
      const node = closeButtonRef.current;
      timeout = setTimeout(() => node && node.focus(), 50);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [refocusState, open]);

  return (
    <FocusLock
      disabled={!open}
      returnFocus
      whiteList={(node) =>
        document.querySelector('#root')?.contains(node) ?? false
      }
    >
      <Styled.Wrapper $canPlay={animationCanPlay} $isOpen={open} tabIndex={-1}>
        {isOpen && <Styled.SideSheetOuter onClick={onClose} />}
        <Styled.SideSheetContent
          $canPlay={animationCanPlay}
          $isOpen={open}
          aria-hidden={!isOpen ? 'true' : 'false'}
          aria-labelledby={titleId}
          aria-modal="true"
          role="dialog"
        >
          {loading && (
            <Styled.SideSheetLoading>
              <Spinner
                $secondaryColor={'var(--colors-brand1Highest)'}
                color="transparent"
                size={50}
              />
            </Styled.SideSheetLoading>
          )}
          <Styled.SideSheetHeader>
            <Styled.SideSheetHeaderTitleGroup>
              <Styled.SideSheetHeaderTitle id={titleId}>
                {title}
              </Styled.SideSheetHeaderTitle>
              <Styled.Button
                aria-label="Close dialog"
                data-testid="close-side-sheet"
                onClick={handleClose}
                ref={closeButtonRef}
                tabIndex={0}
              >
                <CloseIcon />
              </Styled.Button>
            </Styled.SideSheetHeaderTitleGroup>
            {!!subtitle && (
              <Styled.SideSheetHeaderSubtitle>
                {subtitle}
              </Styled.SideSheetHeaderSubtitle>
            )}
          </Styled.SideSheetHeader>
          <Styled.SideSheetBody>{children}</Styled.SideSheetBody>
        </Styled.SideSheetContent>
        <Styled.SideSheetBackground
          $canPlay={animationCanPlay}
          $isOpen={open}
        />
      </Styled.Wrapper>
    </FocusLock>
  );
};

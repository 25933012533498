import { useId } from 'react';

export function AmexLogoSmallIcon({ height = 64 }) {
  const id = useId();
  const clipId = useId();

  const width = `${height * (96 / 64)}`;
  return (
    <svg
      aria-labelledby={id}
      fill="none"
      height={height}
      role="img"
      viewBox="0 0 96 64"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text id={id}>American Express</text>
      <g clipPath={`url(#${clipId})`}>
        <path
          clipRule="evenodd"
          d="M32 64.1783V0H96V3.51795V10.3383H86.5709L84.4424 16.3105L82.3347 10.3383H70.167V18.2641L66.6589 10.3383H56.8276L46.6359 33.4692H54.8517V54.0138H80.3103L84.3038 49.6059L88.2834 54.0138H96V59.388V64.1783H32ZM96 37.2096V47.6592L91.0844 42.417L96 37.2096ZM84.4425 39.226L79.2426 33.4763H72.8225H57.5695V51.4415H72.8225H79.0623L84.3385 45.6432L89.5799 51.4415H96L87.5069 42.4102L96 33.4763H89.6839L84.4425 39.226ZM81.2671 42.4589L72.8225 51.4415V47.2839H62.4574V44.4821H72.566V40.4288H62.4574V37.6339H72.8225V33.4763L81.2671 42.4589ZM50.7126 30.8691L58.6164 12.9109H58.6233H64.9186L72.8224 30.8691H67.3175L65.8061 27.2747H57.5695L56.0858 30.8691H50.7126ZM61.6947 17.3744L59.2612 23.2492H59.2681H64.1283L61.6947 17.3744ZM80.4698 12.9109L84.4633 24.0974L88.4776 12.9109H96V30.8691H91.1676V18.2712L86.564 30.8691H82.2585L77.6549 18.299V30.8691H72.8225V12.9109H80.4698Z"
          fill="#016FD0"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M80.4698 12.9108L84.4632 24.0973L88.4776 12.9108H96V10.3384H86.5709L84.4425 16.3106L82.3278 10.3384H70.1601V18.2642L66.652 10.3384H56.8207L46.629 33.4693H54.8448V54.0138H80.3034L84.2969 49.6059L88.2696 54.0138H95.9931V51.4414H89.573L84.3246 45.643L79.0554 51.4414H72.8086L72.8151 51.4344H72.8225V51.4266L81.2532 42.4588L72.8225 33.491V33.4762H79.2287L84.4217 39.2259L89.6701 33.4762H95.9862V30.869H91.1468V18.2712L86.5432 30.869H82.2446L77.641 18.299V30.869H72.8017V12.9108H80.449H80.4698ZM72.8225 33.491V37.6338H62.4574V40.4287H72.5659V44.482H62.4574V47.2769H72.8225V51.4266L72.8151 51.4344H57.5695V33.4762H72.8086L72.8225 33.491ZM64.8979 12.9108L72.8017 30.869H67.3037L65.7923 27.2746H57.5557L56.072 30.869H50.6988L58.6026 12.9108H64.8979ZM96.0001 51.4413V47.6591L91.0845 42.417L96.0001 37.2096V33.4761L87.507 42.41L96.0001 51.4413ZM64.1214 23.2493H59.2612L61.6878 17.3744L64.1214 23.2493Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect fill="white" height="64" width="96" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { formatDate } from '../../utils/format-date';

import type { TUseUpdateCardMutation } from '../../services/use-update-card/use-update-card-types';
import type { FieldValues } from 'react-hook-form';

export function useEditCardFormSubmit(updateCard: TUseUpdateCardMutation) {
  return async function ({ cardExpiryDate, cardSecurityCode }: FieldValues) {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    const cardDetails = {
      cvv: cardSecurityCode,
      expiry: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    updateCard.mutate({
      card: cardDetails,
    });
  };
}

import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '../query-keys';

import { useUpdateCardRequest } from './use-update-card-request';

import type { TUpdateCardResponse } from './use-update-card-types';
import type {
  PaymentMethodTypes,
  TBaasAddPaymentMethodErrors,
} from '../../types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useUpdateCard(
  cardId: string,
  options?: UseMutationOptions<
    TUpdateCardResponse,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    PaymentMethodTypes.TUpdateCard3dsRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useUpdateCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankUpdate,
    ...options,
  });
}

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  ThreeDsIframe,
  endpoints,
  useFinalizeCard,
} from '@/features/card-management';
import { paymentMethodQueryKeys } from '@/features/card-management/services';
import { CardDialogErrorStates, useCardStore } from '@/store';

import { useUpdateCard } from '../../services/use-update-card/use-update-card';
import { EditCardForm } from '../edit-card-form/edit-card-form';

import styles from './edit-card.module.css';
import { useEditCardFinalize } from './use-edit-card-finalize';
import { useEditCardFinalizeSuccess } from './use-edit-card-finalize-success';

export function EditCard() {
  const queryClient = useQueryClient();

  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const selectedCard = useCardStore().use.selectedCard();
  const threeDSecureId = useCardStore().use.threeDSecureId();

  const {
    setErrorDialogState,
    setFinalizePayload,
    setIs3dsIframeOpen,
    setThreeDSecureId,
  } = useCardStore().use.actions();

  const reloadBankCardList = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.bankCards,
    });
  }, [queryClient]);

  const updateCard = useUpdateCard(selectedCard?.id || '', {
    onError: () => {
      setErrorDialogState(CardDialogErrorStates.BANK_CARD_EDIT_ERROR);
      reloadBankCardList();
    },
    onSuccess: (data) => {
      setThreeDSecureId(data?.three_d_secure?.id);
      setFinalizePayload({
        authorization_id: data?.three_d_secure?.id,
        card_id: selectedCard?.id || '',
      });
      setIs3dsIframeOpen(true);
    },
  });

  const finalizeCard = useFinalizeCard({
    onError: () => {
      reloadBankCardList();
      setErrorDialogState(CardDialogErrorStates.BANK_CARD_EDIT_ERROR);
    },
    onSuccess: useEditCardFinalizeSuccess(),
  });

  useEditCardFinalize(finalizeCard);

  const isProcessing =
    finalizeCard.isPending || updateCard.isPending || is3dsIframeOpen;

  const iframeSrc = `${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${endpoints.BANK_CARDS_3DS_AUTHENTICATE_INIT(threeDSecureId)}`;

  return (
    <div className={styles['edit-card-details']}>
      {is3dsIframeOpen && threeDSecureId ? (
        <ThreeDsIframe src={iframeSrc} />
      ) : (
        <EditCardForm
          card={selectedCard!}
          isProcessing={isProcessing}
          updateCard={updateCard}
        />
      )}
    </div>
  );
}

import { Button, PanelError, Spinner } from '@/components';
import { AddIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';

import { AddressCard } from '../index';

import styles from './address-list.module.css';
import { Styled } from './styles';

import type { AddressesTypes } from '@/features/profile/';

type TAddressListProps = {
  addresses?: AddressesTypes.ListAddresses | null;
  isError: boolean;
  onClickAddBtn: () => void;
  onClickAddress?: (
    address: AddressesTypes.AddressResponse,
    addressId: string,
    isDefault: boolean
  ) => void;
  selectedAddressId?: string;
};

export const AddressList = ({
  addresses,
  isError,
  onClickAddBtn,
  onClickAddress,
  selectedAddressId,
}: TAddressListProps) => {
  const { track } = useAnalytics();

  const onSelectAddress =
    (
      address: AddressesTypes.AddressResponse,
      addressId: string,
      isDefault: boolean
    ) =>
    () => {
      track('View Address Detail');
      onClickAddress?.(address, addressId, isDefault);
    };

  const handleAddButtonClick = () => {
    track('Add Address Started');
    onClickAddBtn();
  };

  if (isError) {
    return (
      <Styled.AddressErrorPanel>
        <Styled.HeadingWrapper>
          <Styled.Header>Addresses</Styled.Header>
        </Styled.HeadingWrapper>
        <PanelError description="addresses" title="Addresses" />
      </Styled.AddressErrorPanel>
    );
  }

  return (
    <Styled.AddressPanel>
      <Styled.HeadingWrapper>
        <Styled.Header>Addresses</Styled.Header>
        {addresses && !!Object.keys(addresses.addresses).length && (
          <Button
            aria-label="Add address"
            className={styles['add-button']}
            level="secondary"
            onClick={handleAddButtonClick}
            size="small"
            variant="branded"
          >
            <AddIcon color={'var(--colors-brand1Highest)'} /> Add
          </Button>
        )}
      </Styled.HeadingWrapper>
      <Styled.AddressListWrapper>
        {addresses ? (
          Object.keys(addresses.addresses).length > 0 ? (
            Object.keys(addresses.addresses).map((addressId) => {
              const address = addresses.addresses[addressId];
              const isDefault = addresses.shipping === addressId;
              return (
                <Styled.AddressListItem key={addressId}>
                  <AddressCard
                    address={address}
                    isDefault={isDefault}
                    onClick={onSelectAddress(address, addressId, isDefault)}
                    selected={addressId === selectedAddressId}
                  />
                </Styled.AddressListItem>
              );
            })
          ) : (
            <Styled.EmptyList>
              <Styled.EmptyListTextWrapper>
                <h3>Delivered directly to you</h3>
                <p>
                  Use your saved addresses to easily select where you would like
                  your purchases to be delivered.
                </p>
              </Styled.EmptyListTextWrapper>
              <Button
                className={styles['empty-list-add-button']}
                level="tertiary"
                onClick={onClickAddBtn}
                size="small"
                variant="neutral"
              >
                <AddIcon /> Add address
              </Button>
            </Styled.EmptyList>
          )
        ) : (
          <Styled.SpinnerWrapper>
            <Spinner
              $secondaryColor={'var(--colors-brand1Highest)'}
              color="transparent"
              size={25}
            />
          </Styled.SpinnerWrapper>
        )}
      </Styled.AddressListWrapper>
    </Styled.AddressPanel>
  );
};

import { CardDialogErrorStates } from './card-store-enums';

import type { ICardStoreInitialValues } from './card-store-types';

export const cardStoreDefaultValues: ICardStoreInitialValues = {
  editCardErrorMessage: null,
  editCardErrorTitle: null,
  errorDialogState: CardDialogErrorStates.NULL,
  finalizePayload: undefined,
  is3dsIframeOpen: false,
  isAddCardOpen: false,
  isAddCardPending: false,
  isCardLimitReachedOpen: false,
  isCardSuccessOpen: false,
  isEditingCard: false,
  isEditingNickname: false,
  isOpenCardList: false,
  isOpenDeleteDialog: false,
  isOpenErrorDialog: false,
  isViewCardOpen: false,
  selectedCard: undefined,
  successDialogState: null,
  threeDSecureId: '',
};

import { cardIs } from './card-is';

import type { PaymentMethodTypes } from '@/features/card-management/types';

export const appendCard = {
  isExpired(card: PaymentMethodTypes.TBankCard) {
    return {
      ...card,
      ...(!!card?.status && { is_expired: cardIs.expired(card) }),
    };
  },

  isInvalid(card: PaymentMethodTypes.TBankCard) {
    return {
      ...card,
      ...(!!card?.status && { is_invalid: cardIs.invalid(card) }),
    };
  },

  isUnavailable(card: PaymentMethodTypes.TBankCard) {
    return {
      ...card,
      ...(!!card?.status && { is_unavailable: cardIs.unavailable(card) }),
    };
  },

  isValidated(card: PaymentMethodTypes.TBankCard) {
    return {
      ...card,
      ...(!!card?.status && { is_validated: cardIs.validated(card) }),
    };
  },

  isVerified(card: PaymentMethodTypes.TBankCard) {
    return {
      ...card,
      ...(!!card?.status && { is_verified: cardIs.verified(card) }),
    };
  },
};

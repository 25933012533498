import { CardDialogSuccessStates } from '@/store';

import type { TCardDialogSuccessStates } from '@/store';

export function getCardSuccessMessage(
  successDialogState: TCardDialogSuccessStates
) {
  const message = {
    description: '',
    title: '',
  };

  switch (successDialogState) {
    case CardDialogSuccessStates.SET_DEFAULT: {
      message.title = 'Default card set';
      message.description =
        'This card will be automatically selected during checkout.';
      return message;
    }
    case CardDialogSuccessStates.EDIT: {
      message.title = 'Card updated';
      message.description = 'You have successfully updated your card details.';
      return message;
    }
    case CardDialogSuccessStates.ADD: {
      message.title = 'New card added';
      message.description =
        'You have successfully added a new card to your flypay wallet.';
      return message;
    }
    case CardDialogSuccessStates.UPDATE_NICKNAME: {
      message.title = 'Card nickname updated';
      message.description =
        'You have successfully updated your card’s nickname.';
      return message;
    }
    default: {
      message.title = 'Card removed';
      message.description =
        'You have successfully removed this card from your flypay wallet.';
      return message;
    }
  }
}

import { CardDialogErrorStates } from '@/store';

import { errorsBankCards } from './errors-bank-card';
import { errorsGiftCards } from './errors-gift-card';

type TCardDialogErrorRecord = {
  buttonText: string | undefined;
  icon?: React.ReactNode;
  isShowTryAgainMessage: boolean | undefined;
  message: React.ReactNode;
  title: string;
  tryAgainMessage: string;
};

type TCardDialogErrorProps = Record<
  keyof typeof CardDialogErrorStates,
  (() => TCardDialogErrorRecord) | undefined
>;

export const cardDialogErrorProps: TCardDialogErrorProps = {
  ...errorsBankCards,
  ...errorsGiftCards,
  [CardDialogErrorStates.NULL]: undefined,
};

export namespace V2QrEnums {
  export enum AcceptedPaymentInstruments {
    EFTPOS = 'Eftpos',
    MASTERCARD = 'Mastercard',
    VISA = 'Visa',
  }

  export enum WalletStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED',
  }

  export enum PaymentWorkflowType {
    DEFERRED = 'deferred',
    IMMEDIATE = 'immediate',
  }

  export enum PaymentContext {
    IN_STORE = 'in_store',
    ONLINE = 'online',
  }

  export enum OrderStatusV2 {
    ABORTED = 'aborted',
    ABORTING = 'aborting',
    CLAIMED = 'claimed',
    EXPIRED = 'expired',
    FINALIZED = 'finalized',
    PENDING = 'pending',
    POSTPROCESSING = 'postprocessing',
    PREPROCESSING = 'preprocessing',
    UNCLAIMED = 'unclaimed',
  }

  export enum CurrencyType {
    AUD = 'AUD',
  }

  export enum PaymentStatus {
    DECLINED = 'declined',
    FAILED = 'failed',
    FINALIZED = 'finalized',
    PENDING = 'pending',
    PROCESSING = 'processing',
  }

  export enum TransactionType {
    PURCHASE = 'purchase',
    REFUND = 'refund',
    REVERSAL = 'reversal',
  }

  export enum TransactionStatus {
    APPROVED = 'approved',
    DECLINED = 'declined',
    FAILED = 'failed',
    PENDING = 'pending',
    PROCESSING = 'processing',
  }

  export enum SchemePaymentMethodType {
    SCHEME = 'scheme',
  }

  export enum AuthorizationStatus {
    ACTION_REQUIRED = 'action_required',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
  }

  export enum AuthorizationActionType {
    AUTHORIZATION_CODE = 'authorizationCode',
    MODIRUM_CHALLENGE = 'modirumChallenge',
  }

  export enum CardAccountType {
    CHEQUE = 'cheque',
    CREDIT = 'credit',
    DEFAULT = 'default',
    SAVINGS = 'savings',
  }
  export enum CardScheme {
    DEFAULT = 'default',
    EFTPOS = 'eftpos',
    MASTERCARD = 'mastercard',
    VISA = 'visa',
  }

  export enum NonSchemePaymentMethodType {
    GIFT_CARD = 'gift_card',
    LOYALTY = 'loyalty',
  }

  export enum SchemePaymentMethodType {
    CARD = 'card',
  }

  export enum NonPaymentInstrumentType {
    INSURANCE = 'insurance',
    LOYALTY = 'loyalty',
    MERCHANT = 'merchant',
    STAFF = 'staff',
  }

  export enum NonPaymentInstrumentExecutionSteps {
    POSTPROCESSING = 'postProcessing',
    PREPROCESSING = 'preProcessing',
  }
  export enum NonPaymentInstrumentProvider {
    MYCOLES = 'coles-team-member',
  }
}

import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '@/features/card-management';

import { useSetDefaultCardRequest } from './use-set-default-card-request';

import type { MutationOptions } from '@tanstack/react-query';

export function useSetDefaultCard(cardId: string, options: MutationOptions) {
  return useMutation({
    mutationFn: useSetDefaultCardRequest(cardId),
    mutationKey: paymentMethodQueryKeys.cardsBankUpdateDefault,
    ...options,
  });
}

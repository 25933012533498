import { BaasErrors, type TBaasErrorResponse } from '@/services';

export function isCardErrorTnc(
  queryError: TBaasErrorResponse | null,
  isLoading: boolean,
  isError: boolean
): boolean {
  const errorCode =
    queryError?.details?.error_number?.toString() ??
    queryError?.details?.toString();

  const isGetBankCardsError = isError && errorCode !== BaasErrors.FORBIDDEN;

  const termsNotAcceptedError =
    errorCode === BaasErrors.TERMS_AND_CONDITIONS_NOT_ACCEPTED;

  return termsNotAcceptedError || (isLoading && !isGetBankCardsError);
}

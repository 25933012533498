import {
  CardsPanel,
  CardsPanelEmpty,
  PanelError,
  PaymentCardListItem,
} from '@/components';
import { useAnalytics } from '@/hooks';
import { useCardStore } from '@/store';

import { useGetBankCards } from '../../services';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '../../types';

export function BankCards() {
  const { track } = useAnalytics();

  const getBankCards = useGetBankCards();

  const isViewCardOpen = useCardStore().use.isViewCardOpen();
  const selectedCard = useCardStore().use.selectedCard();
  const { setIsViewCardOpen, setSelectedCard } = useCardStore().use.actions();

  const handleSetSelectedCard = (card: PaymentMethodTypes.TBankCard) => () => {
    setSelectedCard(card);

    if (!isViewCardOpen) {
      track('View Card Detail');
      setIsViewCardOpen(true);
    }
  };

  return (
    <CardsPanel query={getBankCards} title={'My Cards'}>
      <Styled.CardsWrapper>
        {getBankCards.isError && (
          <PanelError description="payment cards" title="Payment cards" />
        )}

        {getBankCards.isSuccess && !getBankCards?.data?.cards?.length && (
          <CardsPanelEmpty />
        )}

        {!!getBankCards?.data?.cards?.length &&
          getBankCards?.data?.cards?.map((card) => (
            <Styled.PaymentCardItemWrapper key={card.id}>
              <PaymentCardListItem
                card={card}
                onClick={handleSetSelectedCard(card)}
                selected={isViewCardOpen && card.id === selectedCard?.id}
              />
            </Styled.PaymentCardItemWrapper>
          ))}
      </Styled.CardsWrapper>
    </CardsPanel>
  );
}

import { Trigger } from '@radix-ui/react-dialog';

import styles from './dialog-trigger.module.css';

import type { DialogTriggerProps } from '@radix-ui/react-dialog';

export function DialogTrigger({ asChild, children }: DialogTriggerProps) {
  return (
    <Trigger asChild={!!asChild} className={styles['dialog-trigger']}>
      {children}
    </Trigger>
  );
}

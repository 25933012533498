import clsx from 'clsx';
import { format } from 'date-fns';

import { DateFormats } from '@/config/language';
import { formatCurrency } from '@/utils/format/currency';

import styles from './order-row-refund.module.css';

import type { OrderTypes } from '../../types';

export type TOrderRowRefund = {
  isLast: boolean;
  payment: OrderTypes.TPaymentInfo;
};
export function OrderRowRefund({ isLast, payment }: TOrderRowRefund) {
  const refundStyles = clsx(styles['refund'], isLast && styles['is-last']);
  const spaceStyles = clsx(styles['data'], styles['mobile-hidden']);

  const date = format(payment.date, DateFormats.DAY_MONTH_YEAR);

  const hasRefund = payment.hasRefund ? 'Refunded' : 'Paid';

  const amount = formatCurrency(payment.amount);

  return (
    <div className={refundStyles} key={`refund-${payment.id}`}>
      <div className={spaceStyles} />
      <div className={styles['data']}>{date}</div>
      <div className={styles['data']}>{hasRefund}</div>
      <div className={styles['data']}>{amount}</div>
    </div>
  );
}

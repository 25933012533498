import styled from 'styled-components';

const DialogBodyCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const DialogBodyCardListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  list-style: none;
  margin: 0;
  padding: 0;
`;

const DialogBodyCardListItemWrapper = styled.li`
  display: flex;
  width: 100%;
`;

const DialogBodyCardListItem = styled.button<{ $selected: boolean }>`
  align-items: center;
  background: transparent;
  border: 1px solid
    ${(props) => (props.$selected ? 'var(--colors-onSurfaceB)' : 'transparent')};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: var(--space-m);
  padding: var(--space-xs) var(--space-s);
  text-align: left;
  width: 100%;

  &:hover {
    border-color: var(--colors-onSurfaceB);
  }
`;

const DialogBodyCardListItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  line-height: 120%;
  color: var(--colors-onSurfaceA);
  justify-content: center;

  p {
    font-size: var(--fontSizes-14);
    font-weight: var(--fontWeights-normal);
    color: var(--colors-onSurfaceB);
  }
`;

const DialogBodyCardListPaymentCardWrapper = styled.div`
  > div {
    width: 6rem;
    height: 4rem;

    svg {
      width: 3rem;
      height: 2rem;
    }
  }
`;

export const Styled = {
  DialogBodyCardListContainer,
  DialogBodyCardListItem,
  DialogBodyCardListItemTextWrapper,
  DialogBodyCardListItemWrapper,
  DialogBodyCardListPaymentCardWrapper,
  DialogBodyCardListWrapper,
};

import { compareDesc, isSameDay } from 'date-fns';

import { V2QrEnums } from '@/services';

import type { OrderTypes } from '@/features/activity/types';

export function sortOrderPayments(
  orders?: OrderTypes.TOrderInfo[]
): OrderTypes.TOrderInfo[] | undefined {
  return orders?.map((order) => ({
    ...order,
    payments: sortPayments(order?.payments),
  }));
}

export function sortPayments(
  payments?: OrderTypes.TPaymentInfo[]
): OrderTypes.TPaymentInfo[] {
  if (!payments) {
    return [];
  }
  return payments?.sort((a, b) => {
    const dateComparison = compareDesc(b.date, a.date);
    if (!isSameDay(a.date, b.date)) {
      return dateComparison;
    } else {
      const aHasPending = a.transactions.some(
        (transaction) =>
          transaction.status === V2QrEnums.TransactionStatus.PENDING
      );
      const bHasPending = b.transactions.some(
        (transaction) =>
          transaction.status === V2QrEnums.TransactionStatus.PENDING
      );
      if (aHasPending && !bHasPending) {
        return 1;
      } else if (!aHasPending && bHasPending) {
        return -1;
      } else {
        return 0;
      }
    }
  });
}

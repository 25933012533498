import { Dialog } from '@/components';

import { CardListDialogBody } from './card-list-dialog-body';

import type { TCardListDialogProps } from './card-list-dialog-types';

export const CardListDialog = ({
  cardId,
  cards,
  onCloseDialog,
  onDeleteError,
  onDeleteSuccess,
  open,
}: TCardListDialogProps) => {
  const title = `Which card would you like to set as your new default card?`;
  const intro =
    'You need to set a new default card before removing your current default card.';

  return (
    <Dialog
      body={
        <CardListDialogBody
          cardId={cardId}
          cards={cards}
          onDeleteError={onDeleteError}
          onDeleteSuccess={onDeleteSuccess}
        />
      }
      hasCloseIcon
      intro={intro}
      isOpen={open}
      onOpenChange={onCloseDialog}
      title={title}
    />
  );
};

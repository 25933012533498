import { useEffect } from 'react';

import { SuccessDialog } from '@/components';
import { useAnalytics } from '@/hooks';
import { CardDialogSuccessStates, useCardStore } from '@/store';
import { capitalizeFirstChar } from '@/utils';

import { getCardSuccessMessage } from './get-card-success-message';

export function CardDialogSuccess() {
  const { track } = useAnalytics();
  const isCardSuccessOpen = useCardStore().use.isCardSuccessOpen();
  const successDialogState = useCardStore().use.successDialogState();
  const setIsCardSuccessOpen =
    useCardStore().use.actions().setIsCardSuccessOpen;

  const successMessage = getCardSuccessMessage(successDialogState);

  const handleCloseSuccessDialog = () => {
    setIsCardSuccessOpen(false);
  };

  useEffect(() => {
    if (successDialogState === CardDialogSuccessStates.ADD) {
      track(`${capitalizeFirstChar(successDialogState)} Card Confirmed`);
    }
  }, [successDialogState, track]);

  return (
    <SuccessDialog
      buttonText="Continue"
      description={successMessage.description}
      isOpen={isCardSuccessOpen}
      onOpenChange={handleCloseSuccessDialog}
      title={successMessage.title}
    />
  );
}

import { Button } from '@/components';
import { EditIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';
import { useCardStore } from '@/store';

import styles from './view-card-footer.module.css';

export function ViewCardFooterEditCardButton() {
  const { track } = useAnalytics();

  const selectedCard = useCardStore().use.selectedCard();
  const setIsEditingCard = useCardStore().use.actions().setIsEditingCard;

  const handleEdit = () => {
    track('Update Card Started');
    setIsEditingCard(true);
  };

  return (
    <Button
      className={styles['action-button']}
      level="primary"
      onClick={handleEdit}
      size="medium"
      variant="branded"
    >
      {!!selectedCard?.is_validated ? (
        'Verify card'
      ) : (
        <>
          <EditIcon color={'var(--colors-onBrand1High)'} />
          Edit card
        </>
      )}
    </Button>
  );
}

import { useEffect } from 'react';

import { CardDialogErrorStates, useCardStore } from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { use3dsCardListener } from '../../hooks/use-3ds-card-listener';

import type { TUseFinalizeCardMutation } from '../../services/use-finalize-card/use-finalize-card-types';

// TODO: potentially same as add card, check and dedupe
export function useEditCardFinalize(finalizeCard: TUseFinalizeCardMutation) {
  const { message } = use3dsCardListener();

  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const finalizePayload = useCardStore().use.finalizePayload();

  const { setErrorDialogState, setIs3dsIframeOpen } =
    useCardStore().use.actions();

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS) {
        if (finalizePayload) {
          finalizeCard.mutate(finalizePayload);
        } else {
          setIs3dsIframeOpen(false);
          setErrorDialogState(CardDialogErrorStates.BANK_CARD_EDIT_ERROR);
        }
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        setErrorDialogState(CardDialogErrorStates.BANK_CARD_EDIT_ERROR);
      }
    }
  }, [
    finalizeCard,
    finalizePayload,
    is3dsIframeOpen,
    message,
    setErrorDialogState,
    setIs3dsIframeOpen,
  ]);
}

import { TryAgainMessage } from '@/components';
import { ErrorIcon } from '@/components/icons';
import { BUTTON_TEXT_TRY_AGAIN } from '@/config/language/errors';
import { useCardStore } from '@/store';

import { cardDialogErrorProps } from './card-dialog-error-props';
import { Styled } from './styles';

type TCardDialogErrorBodyProps = {
  handleOpenChange: () => void;
};

export function CardDialogErrorBody({
  handleOpenChange,
}: TCardDialogErrorBodyProps) {
  const errorDialogState = useCardStore().use.errorDialogState();
  const dialogProps = cardDialogErrorProps[errorDialogState]?.();

  return (
    <Styled.DialogWrapper>
      {dialogProps?.icon ?? <ErrorIcon />}

      <Styled.DialogHeader>
        <Styled.DialogTitle>{dialogProps?.title}</Styled.DialogTitle>

        <Styled.DialogDescription>
          {dialogProps?.message}{' '}
          {dialogProps?.isShowTryAgainMessage && (
            <TryAgainMessage what={dialogProps?.tryAgainMessage} />
          )}
        </Styled.DialogDescription>
      </Styled.DialogHeader>

      <Styled.ActionButton onClick={handleOpenChange} size="medium">
        {dialogProps?.buttonText || BUTTON_TEXT_TRY_AGAIN}
      </Styled.ActionButton>
    </Styled.DialogWrapper>
  );
}

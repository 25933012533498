import { V2QrEnums, type V2QrTypes } from '@/services';

import type { OrderTypes } from '../types';

export const orderHas = {
  myColes(instruments?: V2QrTypes.TNonPaymentInstrumentResponse[]): boolean {
    return (
      instruments?.some(
        (instrument) =>
          instrument.provider === V2QrEnums.NonPaymentInstrumentProvider.MYCOLES
      ) ?? false
    );
  },

  refunds(payments?: OrderTypes.TPaymentInfo[]): boolean {
    return payments?.some((payment) => payment.hasRefund) ?? false;
  },
};

import { PaymentCardSchemes } from './payment-card-enums';

import type { TCardSchemes } from './payment-card-types';

export function getDisplayScheme(schemes?: string[]): TCardSchemes {
  if (!Array.isArray(schemes) || !schemes?.length) {
    return PaymentCardSchemes.DEFAULT;
  }

  if (schemes.includes('amex') || schemes.includes('american-express')) {
    return PaymentCardSchemes.AMEX;
  }

  if (schemes.includes('mastercard')) {
    return PaymentCardSchemes.MASTERCARD;
  }

  if (schemes.includes('visa')) {
    return PaymentCardSchemes.VISA;
  }

  if (schemes.includes('eftpos')) {
    return PaymentCardSchemes.EFTPOS;
  }

  return PaymentCardSchemes.DEFAULT;
}

import { ExclamationMarkIcon } from '@/components/icons';

import styles from './orders-table-empty.module.css';

export function OrdersTableEmpty() {
  return (
    <div className={styles['container']} role="alert">
      <ExclamationMarkIcon height={62} width={62} />
      <div className={styles['message']}>
        <h1> Nothing to see here</h1>
        <p>We found no orders made with your flypay account</p>
      </div>
    </div>
  );
}

import { format } from 'date-fns';

import { DateFormats } from '@/config/language';

import type { OrderTypes } from '@/features/activity/types';

export const groupOrdersByDate = (
  orders?: OrderTypes.TOrderInfo[]
): OrderTypes.TSortedOrders[] | undefined => {
  if (!orders || orders.length === 0) {
    return undefined;
  }

  return orders.reduce<OrderTypes.TSortedOrders[]>((result, item) => {
    const formattedDate = format(
      item.created_at.iso,
      DateFormats.DAY_MONTH_YEAR_LONG
    );
    const existingGroup = result.find(
      (group) => group.create_at === formattedDate
    );
    if (existingGroup) {
      existingGroup.orders.push(item);
    } else {
      result.push({ create_at: formattedDate, orders: [item] });
    }
    return result;
  }, []);
};

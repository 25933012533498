import { Form } from '@/components';
import { useCardStore } from '@/store';

import { useGetPublicKey } from '../../services';
import { useAuthorizeCard } from '../../services/use-authorize-card/use-authorize-card';

import { AddCardFormBody } from './add-card-form-body';
import { AddCardFormFooter } from './add-card-form-footer';
import { useAddCardFormInit } from './use-add-card-form-init';
import { useAddCardFormReset } from './use-add-card-form-reset';
import { useAddCardFormSubmit } from './use-add-card-form-submit';

export function AddCardForm() {
  const isAddCardOpen = useCardStore().use.isAddCardOpen();

  const authorizeCard = useAuthorizeCard();
  const getPublicKey = useGetPublicKey({ enabled: isAddCardOpen });

  const form = useAddCardFormInit();
  const handleSubmit = useAddCardFormSubmit(authorizeCard, getPublicKey?.data);

  useAddCardFormReset(form);

  return (
    <Form config={form} onSubmitForm={handleSubmit}>
      <AddCardFormBody />
      <AddCardFormFooter />
    </Form>
  );
}

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

import { CardInputFieldNames } from '@/components/card-input';
import { validate } from '@/utils';

import type { FieldValues } from 'react-hook-form';

export function useAddCardFormInit() {
  const DEFAULT_FORM_VALUES = {
    [CardInputFieldNames.CARD_ALIAS]: '',
    [CardInputFieldNames.CARD_NUMBER]: '',
    [CardInputFieldNames.CARD_EXPIRY_DATE]: '',
    [CardInputFieldNames.CARD_SECURITY_CODE]: '',
    [CardInputFieldNames.CARD_IS_DEFAULT]: false,
  } as FieldValues;

  const validationSchema = Joi.object({
    [CardInputFieldNames.CARD_ALIAS]: validate.cardAlias(),
    [CardInputFieldNames.CARD_NUMBER]: validate.cardNumber(),
    [CardInputFieldNames.CARD_EXPIRY_DATE]: validate.cardExpiryDate(),
    [CardInputFieldNames.CARD_SECURITY_CODE]: validate.cardSecurityCode(),
    [CardInputFieldNames.CARD_IS_DEFAULT]: validate.cardIsDefault(),
  });

  return useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });
}

import { useCardStore } from '@/store';

export function useViewCardReset() {
  const {
    setIs3dsIframeOpen,
    setIsEditingCard,
    setIsEditingNickname,
    setIsViewCardOpen,
  } = useCardStore().use.actions();

  return function onViewCardClose() {
    setIs3dsIframeOpen(false);
    setIsViewCardOpen(false);
    setIsEditingNickname(false);
    setIsEditingCard(false);
  };
}

import { useMutation } from '@tanstack/react-query';

import { paymentMethodQueryKeys } from '../query-keys';

import { useFinalizeCardRequest } from './use-finalize-card-request';

import type {
  PaymentMethodTypes,
  TBaasAddPaymentMethodErrors,
} from '../../types';
import type { TBaasErrorResponse } from '@/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useFinalizeCard(
  options?: UseMutationOptions<
    unknown,
    TBaasErrorResponse<TBaasAddPaymentMethodErrors>,
    PaymentMethodTypes.TAddCard3dsFinalizeRequest,
    unknown
  >
) {
  return useMutation({
    mutationFn: useFinalizeCardRequest(),
    mutationKey: paymentMethodQueryKeys.cardsBankAddFinalize,
    ...options,
  });
}

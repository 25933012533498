import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { ButtonSpinner } from '@/components';
import { DownArrowIcon } from '@/components/icons';

import { LoadMoreMessage } from './load-more-message';
import { OrdersTableData } from './orders-table-data';
import styles from './orders-table.module.css';

import type { OrderTypes } from '@/features/activity/types';
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';

TimeAgo.addDefaultLocale(en);

interface TOrdersTableProps {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<OrderTypes.TOrdersList>>;
  hasNextPage?: boolean;
  isError: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onSelectOrder?: (id: string) => void;
  orders?: OrderTypes.TSortedOrders[];
}

export function OrdersTable({
  fetchNextPage,
  hasNextPage,
  isError,
  isFetchingNextPage,
  isLoading,
  onSelectOrder,
  orders,
}: TOrdersTableProps) {
  return (
    <>
      <div aria-live="polite" className={styles['container']}>
        <OrdersTableData
          isError={isError}
          isLoading={isLoading}
          onClick={onSelectOrder}
          orders={orders}
        />
      </div>
      {hasNextPage && (
        <ButtonSpinner
          level="secondary"
          loading={isFetchingNextPage}
          onClick={fetchNextPage}
          size="small"
          style={{ margin: '1rem auto 0 auto' }}
          variant="neutral"
        >
          {!isFetchingNextPage && <DownArrowIcon />} Load More
        </ButtonSpinner>
      )}
      <LoadMoreMessage
        hasNextPage={hasNextPage}
        isError={isError}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        ordersListLength={orders?.length}
      />
    </>
  );
}

import { PaymentCard } from '@b707/ponyta';
import { useState } from 'react';

import { ButtonSpinner } from '@/components';
import {
  type PaymentMethodTypes,
  getBankCardLabel,
} from '@/features/card-management';
import { useDeleteCard } from '@/features/card-management';
import { useSetDefaultCard } from '@/features/card-management/services';

import { Styled } from './styles';

import type { TCardListDialogProps } from './card-list-dialog-types';

export function CardListDialogBody({
  cardId,
  cards,
  onDeleteError,
  onDeleteSuccess,
}: Partial<TCardListDialogProps>) {
  const [selectedCard, setSelectedCard] =
    useState<PaymentMethodTypes.TBankCard>();

  const onClickCard = (card: PaymentMethodTypes.TBankCard) => () => {
    setSelectedCard(card);
  };

  const handleSetDefaultCard = () => {
    selectedCard && setDefaultCard.mutate();
  };

  const setDefaultCard = useSetDefaultCard(
    (selectedCard && selectedCard?.id) || '',
    {
      onSuccess: () => {
        deleteCard?.mutate();
      },
    }
  );
  const deleteCard = useDeleteCard(cardId || '', {
    onError: () => {
      onDeleteError?.();
    },
    onSuccess: () => {
      onDeleteSuccess?.();
    },
  });

  const loading = setDefaultCard.isPending || deleteCard.isPending;

  return (
    <Styled.DialogBodyCardListContainer>
      <Styled.DialogBodyCardListWrapper>
        {cards?.map((card: PaymentMethodTypes.TBankCard) => {
          return (
            <Styled.DialogBodyCardListItemWrapper key={card.id}>
              <Styled.DialogBodyCardListItem
                $selected={selectedCard?.id === card.id}
                aria-label={getBankCardLabel(card)}
                onClick={onClickCard(card)}
                role="button"
                tabIndex={0}
              >
                <Styled.DialogBodyCardListPaymentCardWrapper>
                  <PaymentCard
                    cardType={card.data.type}
                    schemes={card.data.schemes}
                    size="sm"
                  />
                </Styled.DialogBodyCardListPaymentCardWrapper>

                <Styled.DialogBodyCardListItemTextWrapper>
                  {card.alias || card.data.issuer_name}
                  <p>••• {card.data.visualization.last_four_digits}</p>
                </Styled.DialogBodyCardListItemTextWrapper>
              </Styled.DialogBodyCardListItem>
            </Styled.DialogBodyCardListItemWrapper>
          );
        })}
      </Styled.DialogBodyCardListWrapper>

      <ButtonSpinner
        disabled={loading}
        level="primary"
        loading={loading}
        onClick={handleSetDefaultCard}
        size="large"
        spinnerColor={'var(-colors-onSurfaceE)'}
        variant="neutral"
      >
        {loading ? 'Loading' : 'Continue'}
      </ButtonSpinner>
    </Styled.DialogBodyCardListContainer>
  );
}

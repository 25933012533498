import PaymentIcon from '@/assets/images/payment-icon.svg';
import { Button } from '@/components';
import { AddIcon } from '@/components/icons';

import styles from './panel-card.module.css';
import { Styled } from './styles';

type TEmptyPanelCard = {
  onClickAdd?: () => void;
};

export function CardsPanelEmpty({ onClickAdd }: TEmptyPanelCard) {
  return (
    <Styled.PanelCardContentContainer>
      <Styled.EmptyCardIcon alt="" src={PaymentIcon} />

      <Styled.PanelCardContent>
        <Styled.PanelCardTitle>Shop in store or online</Styled.PanelCardTitle>

        <Styled.PanelCardDescription>
          Pay instore with QR or checkout online using your payment cards
        </Styled.PanelCardDescription>

        <Button
          className={styles['panel-card-button']}
          level="tertiary"
          onClick={onClickAdd}
          size="small"
          variant="neutral"
        >
          <Styled.ButtonWrapper>
            <AddIcon height={16} width={16} />
            Add Payment card
          </Styled.ButtonWrapper>
        </Button>
      </Styled.PanelCardContent>
    </Styled.PanelCardContentContainer>
  );
}

export enum PaymentCardSchemes {
  AMEX = 'amex',
  DEFAULT = 'default',
  EFTPOS = 'eftpos',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
}

export enum PaymentCardTypes {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { CardInput, Checkbox, FormHint, TextField } from '@/components';
import { CardInputFieldNames, CardInputLabels } from '@/components/card-input';
import { MAX_CHARS_CARD_NICKNAME } from '@/features/profile';
import { useCardStore } from '@/store';

import styles from './add-card-form-body.module.css';

export function AddCardFormBody() {
  const formContext = useFormContext();

  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const isAddCardOpen = useCardStore().use.isAddCardOpen();
  const isAddCardPending = useCardStore().use.isAddCardPending();

  const formContainerStyles = clsx(
    styles['add-card-form-body-form-container'],
    is3dsIframeOpen && styles['hidden']
  );

  return (
    <div className={formContainerStyles}>
      <FormHint />

      <div className={styles['add-card-form-body-nickname']}>
        <TextField
          disabled={isAddCardPending}
          helpText="Give this card a nickname (optional)"
          label={CardInputLabels.CARD_ALIAS}
          maxLength={MAX_CHARS_CARD_NICKNAME}
          name={CardInputFieldNames.CARD_ALIAS}
        />
      </div>

      <CardInput
        isAddCardOpen={isAddCardOpen}
        isSubmitting={isAddCardPending}
      />

      <Checkbox
        disabled={isAddCardPending}
        id="cardIsDefault"
        label="Set as default payment card"
        {...formContext.register(CardInputFieldNames.CARD_IS_DEFAULT)}
      />
    </div>
  );
}

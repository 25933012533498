import { useEffect } from 'react';

import { sentry } from '@/features/monitoring';
import { CardDialogErrorStates, useCardStore } from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { use3dsCardListener } from '../../hooks/use-3ds-card-listener';

import type { TUseFinalizeCardMutation } from '../../services/use-finalize-card/use-finalize-card-types';

export function useAddCardFinalize(finalizeCard: TUseFinalizeCardMutation) {
  const { message, setMessage } = use3dsCardListener();

  const finalizePayload = useCardStore().use.finalizePayload();
  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const { setErrorDialogState, setIs3dsIframeOpen } =
    useCardStore().use.actions();

  useEffect(() => {
    if (is3dsIframeOpen) {
      if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_SUCCESS) {
        if (finalizePayload) {
          finalizeCard.mutate(finalizePayload);
        } else {
          sentry.captureLog('Card Add Failed 3DS Authorize', message);
          setErrorDialogState(CardDialogErrorStates.BANK_CARD_ADD_ERROR);
        }
        setMessage(null);
      } else if (message === PaymentMethodEnums.MpgsPostMessage.AUTH_FAIL) {
        setIs3dsIframeOpen(false);
        setMessage(null);
        setErrorDialogState(CardDialogErrorStates.BANK_CARD_ADD_ERROR);
      }
    }
  }, [
    finalizeCard,
    finalizePayload,
    is3dsIframeOpen,
    message,
    setIs3dsIframeOpen,
    setErrorDialogState,
    setMessage,
  ]);
}

import { useId } from 'react';

export function MastercardLogoIcon({ height = 24 }) {
  const id = useId();
  const firstMaskId = useId();
  const secondMaskId = useId();

  const width = `${height * (40 / 24)}`;
  return (
    <svg
      aria-labelledby={id}
      fill="none"
      height={height}
      role="img"
      viewBox="0 0 40 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text id={id}>Mastercard</text>
      <path
        clipRule="evenodd"
        d="M14.916 21.4339H25.4229V2.56641H14.916V21.4339Z"
        fill="#FF5F00"
        fillRule="evenodd"
      />
      <mask
        height="24"
        id={firstMaskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="21"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0.742188 0.00195312H20.1703V23.9994H0.742188V0.00195312Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#${firstMaskId})`}>
        <path
          clipRule="evenodd"
          d="M15.584 12.0026C15.58 8.32011 17.2714 4.84057 20.1703 2.5672C14.9562 -1.52759 7.4073 -0.623044 3.30929 4.58694C-0.788393 9.79692 0.116533 17.3403 5.33095 21.4347C9.68546 24.8543 15.8158 24.8543 20.1703 21.4347C17.2724 19.1617 15.581 15.6838 15.584 12.0026Z"
          fill="#EB001B"
          fillRule="evenodd"
        />
      </g>
      <mask
        height="24"
        id={secondMaskId}
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="20"
        x="20"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M20.1699 0H39.5991V24.0002H20.1699V0Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask={`url(#${secondMaskId})`}>
        <path
          clipRule="evenodd"
          d="M39.5993 12.0021C39.599 18.6286 34.2228 24.0002 27.5911 24.0002C24.8994 24.0002 22.286 23.0964 20.1699 21.4342C25.384 17.3398 26.2893 9.79644 22.1912 4.58646C21.6002 3.83522 20.9217 3.15698 20.1699 2.56672C25.3833 -1.52907 32.9323 -0.626192 37.0313 4.58346C38.6947 6.69751 39.599 9.30917 39.5993 11.9988V12.0021Z"
          fill="#F79E1B"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

import { useCardStore } from '@/store';

export function useUpdateCardNicknameError() {
  const setIsOpenErrorDialog =
    useCardStore().use.actions().setIsOpenErrorDialog;

  return () => {
    setIsOpenErrorDialog(true);
  };
}

import { Dialog } from '@/components';
import { CardDialogErrorStates, useCardStore } from '@/store';

import { CardDialogErrorBody } from './card-dialog-error-body';
import { cardDialogErrorProps } from './card-dialog-error-props';

type TCardDialogErrorProps = {
  handleOpenChange: () => void;
};

export function CardDialogError({ handleOpenChange }: TCardDialogErrorProps) {
  const errorDialogState = useCardStore().use.errorDialogState();
  const isOpenCardDialogError = errorDialogState !== CardDialogErrorStates.NULL;
  const dialogProps = cardDialogErrorProps[errorDialogState]?.();

  return (
    <Dialog
      body={<CardDialogErrorBody handleOpenChange={handleOpenChange} />}
      description={dialogProps?.title}
      isOpen={isOpenCardDialogError}
      onOpenChange={handleOpenChange}
    />
  );
}

import { useMutation } from '@tanstack/react-query';

import {
  type PaymentMethodTypes,
  paymentMethodQueryKeys,
} from '@/features/card-management';

import { useUpdateCardNicknameError } from './use-update-card-nickname-error';
import { useUpdateCardNicknameRequest } from './use-update-card-nickname-request';
import { useUpdateCardNicknameSuccess } from './use-update-card-nickname-success';

import type { IUseUpdateCardRequestPayload } from './use-update-card-nickname-types';
import type { UseMutationOptions } from '@tanstack/react-query';

export function useUpdateCardNickname(
  cardId: string,
  options?: UseMutationOptions<
    PaymentMethodTypes.PaymentMethod,
    unknown,
    IUseUpdateCardRequestPayload,
    unknown
  >
) {
  return useMutation<
    PaymentMethodTypes.PaymentMethod,
    unknown,
    IUseUpdateCardRequestPayload,
    unknown
  >({
    mutationFn: useUpdateCardNicknameRequest(cardId),
    mutationKey: paymentMethodQueryKeys.updateCardNickname,
    onError: useUpdateCardNicknameError(),
    onSuccess: useUpdateCardNicknameSuccess(),
    ...options,
  });
}

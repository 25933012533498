import { useId } from 'react';

export const VisaLogoIcon = ({ color = '#fff', height = 16 }) => {
  const id = useId();
  const width = `${height * (50 / 16)}`;
  return (
    <svg
      aria-labelledby={id}
      fill="none"
      height={height}
      role="img"
      viewBox="0 0 50 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <text id={id}>Visa</text>
      <path
        clipRule="evenodd"
        d="M25.8431 5.11528C25.8151 7.34409 27.8156 8.58798 29.3226 9.32744C30.8711 10.0862 31.3912 10.5728 31.3852 11.2512C31.3734 12.2897 30.15 12.748 29.005 12.7659C27.0074 12.7972 25.8461 12.2229 24.9227 11.7884L24.2031 15.1792C25.1295 15.6092 26.8449 15.9841 28.6238 16.0005C32.7992 16.0005 35.5311 13.9249 35.5459 10.7066C35.5621 6.62244 29.9358 6.39629 29.9742 4.57074C29.9875 4.01724 30.512 3.42654 31.6615 3.2763C32.2303 3.20038 33.8008 3.14238 35.5813 3.96809L36.2802 0.687417C35.3227 0.336196 34.092 0 32.5598 0C28.6297 0 25.8654 2.10384 25.8431 5.11528"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M41.4863 10.2734L43.5799 4.46172L44.7841 10.2734H41.4863ZM46.3914 0.28125H42.9962C42.2337 0.28125 41.5911 0.729081 41.3045 1.4165L35.3398 15.758H39.5122L40.3427 13.4473H45.4414L45.9231 15.758H49.6006L46.3914 0.28125Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.7831 0.283203L17.4941 15.76H21.4701L24.7575 0.283203H20.7831Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.9025 0.283203L10.7641 10.8173L9.09006 1.86031C8.89354 0.860478 8.11785 0.283203 7.25649 0.283203H0.491038L0.396484 0.732526C1.78534 1.03599 3.36331 1.52551 4.31921 2.04925C4.90432 2.36913 5.07129 2.64892 5.26336 3.40916L8.43401 15.76H12.6361L19.0778 0.283203H14.9025"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

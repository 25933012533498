import { clsx } from 'clsx';

import { Spinner } from '@/components';

import { Button } from '../button/button';

import styles from './button-spinner.module.css';

import type {
  TButtonLevel,
  TButtonSize,
  TButtonVariant,
} from '../button/button-types';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

interface IButtonSpinnerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  disabled?: boolean;
  level?: TButtonLevel;
  loading?: boolean;
  onClick?: () => void;
  size?: TButtonSize;
  spinnerColor?: string;
  spinnerSize?: number;
  spinnerThickness?: number;
  type?: 'button' | 'reset' | 'submit';
  variant?: TButtonVariant;
}

export const ButtonSpinner = ({
  children,
  className,
  disabled,
  level,
  loading,
  onClick,
  size,
  spinnerColor,
  spinnerSize = 14,
  spinnerThickness,
  type,
  variant,
  ...props
}: IButtonSpinnerProps) => {
  const handleButtonClick = loading ? undefined : onClick;
  return (
    <Button
      className={clsx(
        styles['button-spinner'],
        {
          [styles['button-spinner-loading']]: loading,
        },
        className
      )}
      disabled={!!disabled}
      level={level}
      onClick={handleButtonClick}
      size={size}
      type={type || 'button'}
      variant={variant}
      {...props}
    >
      {loading && (
        <Spinner
          $secondaryColor={spinnerColor}
          $thickness={spinnerThickness}
          color="transparent"
          size={spinnerSize}
        />
      )}
      {children}
    </Button>
  );
};

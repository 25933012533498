import { GiftCardTypes } from '@/features/gift-cards';

import { PaymentMethodEnums } from '../enums';

export const getPillText = (
  status: GiftCardTypes.GiftCardStatus | PaymentMethodEnums.BankCardStatus,
  isDefault: boolean
): string | undefined => {
  switch (status) {
    case PaymentMethodEnums.BankCardStatus.INVALID: {
      return 'Unavailable';
    }
    case PaymentMethodEnums.BankCardStatus.VALIDATED: {
      return 'Unverified';
    }
    case PaymentMethodEnums.BankCardStatus.EXPIRED: {
      return 'Expired';
    }
    case GiftCardTypes.GiftCardStatus.USED: {
      return 'Used';
    }
    default: {
      if (isDefault) {
        return 'Default';
      }
    }
  }
};

import { CardDialogSuccessStates } from '@/store';

import { useCardShowSuccessDialog } from '../../hooks/use-card-show-success-dialog';
import { useViewCardReset } from '../../hooks/use-view-card-reset';

export function useEditCardFinalizeSuccess() {
  const showSuccessDialog = useCardShowSuccessDialog();
  const viewCardReset = useViewCardReset();

  return function editCardDetailsFinalizeSuccessCallback() {
    viewCardReset();
    showSuccessDialog(CardDialogSuccessStates.EDIT);
  };
}

import styled from 'styled-components';

import { BreakpointRems } from '@/config/breakpoints';

const AddressPanel = styled.div`
  background-color: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  padding: var(--space-xl) var(--space-l);
  width: 100%;
`;

const AddressErrorPanel = styled(AddressPanel)`
  @media (min-width: ${BreakpointRems.sm}) {
    max-width: 520px;
  }
`;

const AddressListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const EmptyListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);

  h3 {
    font-size: var(--fontSizes-16);
    font-weight: var(--fontWeights-bold);
  }

  p {
    font-size: var(--fontSizes-14);
    font-weight: var(--fontWeights-normal);
    color: var(--colors-onSurfaceB);
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-l);
`;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 100%;
  width: 100%;

  @media (min-width: ${BreakpointRems.xs}) {
    min-width: 400px;
  }
`;

const AddressListItem = styled.li`
  width: 100%;

  @media (min-width: ${BreakpointRems.xs}) {
    min-width: 400px;
  }
`;

const Header = styled.h2`
  font-style: normal;
  font-weight: var(--fontWeights-bold);
  font-size: var(--fontSizes-20);
  line-height: 1.4;
  color: var(--colors-onSurfaceA);
  display: flex;
  gap: var(--space-s);
  align-items: center;
  margin: 0;
`;

export const Styled = {
  AddressErrorPanel,
  AddressListItem,
  AddressListWrapper,
  AddressPanel,
  EmptyList,
  EmptyListTextWrapper,
  Header,
  HeadingWrapper,
  SpinnerWrapper,
};

import { Button, Dialog } from '@/components';
import { CheckIcon } from '@/components/icons';
import { useSetA11yActions } from '@/store';

import styles from './action-button.module.css';
import { Styled } from './styles';

type TSuccessDialog = {
  buttonText?: string;
  description?: string;
  isOpen: boolean;
  onOpenChange: () => void;
  title: string;
};

const SuccessDialogBody = ({
  buttonText,
  description,
  onOpenChange,
  title,
}: Partial<TSuccessDialog>) => {
  return (
    <Styled.DialogWrapper>
      <CheckIcon color={'var(--colors-onSurfaceInverse)'} />
      <Styled.DialogHeader>
        <Styled.DialogTitle>{title}</Styled.DialogTitle>
        {!!description && (
          <Styled.DialogDescription>{description}</Styled.DialogDescription>
        )}
      </Styled.DialogHeader>

      <Button
        className={styles['action-button']}
        onClick={onOpenChange}
        size="medium"
      >
        {buttonText}
      </Button>
    </Styled.DialogWrapper>
  );
};

export const SuccessDialog = ({
  buttonText,
  description,
  isOpen,
  onOpenChange,
  title,
}: TSuccessDialog) => {
  const { setA11yAnnouncement } = useSetA11yActions();

  const handleOpenChange = () => {
    if (isOpen) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }
    onOpenChange?.();
  };

  return (
    <Dialog
      body={
        <SuccessDialogBody
          buttonText={buttonText}
          description={description}
          onOpenChange={handleOpenChange}
          title={title}
        />
      }
      description={title}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
    />
  );
};

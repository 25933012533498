import {
  CardDialogErrorStates,
  useCardStore,
  useSetA11yActions,
} from '@/store';

export function useCardDialogErrorReset() {
  const errorDialogState = useCardStore().getState().errorDialogState;

  const { setA11yAnnouncement } = useSetA11yActions();
  const { setErrorDialogState, setIsAddCardPending } =
    useCardStore().getState().actions;

  return function onOpenChange() {
    if (errorDialogState !== CardDialogErrorStates.NULL) {
      // If already open, we will be closing this dialog
      setA11yAnnouncement('Dialog closed');
    }

    setErrorDialogState(CardDialogErrorStates.NULL);
    setIsAddCardPending(false);
  };
}

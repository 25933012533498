import { Spinner } from '@/components';

import styles from './orders-table-loading.module.css';

export function OrdersTableLoading() {
  return (
    <div className={styles['loading']}>
      Loading payment history...
      <Spinner
        $secondaryColor={'var(--colors-onSurfaceD)'}
        color={'var(--colors-onSurfaceA)'}
        size={32}
      />
    </div>
  );
}

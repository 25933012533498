import { format, max } from 'date-fns';

import { PendingIcon, RefundIcon, TickIcon } from '@/components/icons';
import { DateFormats } from '@/config/language';
import { formatCurrency } from '@/utils/format/currency';

import { ViewOrderStatus } from '../enums';

import { Styled } from './styles';

import type { OrderTypes } from '@/features/activity/types';

type TPaymentSummary = {
  amount?: number;
  hasMyColes: boolean;
  orderStatus: `${ViewOrderStatus}`;
  payments: OrderTypes.TPaymentInfo[];
};

const getPaymentHeader = (payment: OrderTypes.TPaymentInfo) => {
  let paymentIcon = <TickIcon />;
  let paymentText = 'Payment';

  if (payment.hasRefund) {
    paymentIcon = <RefundIcon />;
    paymentText = 'Refund';
  }
  if (payment.isPending) {
    paymentIcon = <PendingIcon />;
    paymentText = 'Pending';
  }
  return (
    <Styled.PaymentHeader>
      {paymentIcon}
      <Styled.TextS $weight="bold">{paymentText}</Styled.TextS>
      <Styled.TextXS $color="onSurfaceC">
        {!payment.isPending && format(payment.date, DateFormats.DAY_MONTH)}
      </Styled.TextXS>
    </Styled.PaymentHeader>
  );
};

export const PaymentSummary = ({
  amount,
  hasMyColes,
  orderStatus,
  payments,
}: TPaymentSummary) => {
  const transactionDates = payments.map((transaction) => transaction.date);
  return (
    <Styled.SummaryWrapper>
      <Styled.SubHeading>Payment Summary</Styled.SubHeading>
      {hasMyColes && (
        <Styled.SummaryRow>
          <Styled.TextS $color="onSurfaceC">mycoles discount card</Styled.TextS>
          <Styled.TextS>
            {orderStatus === ViewOrderStatus.PENDING
              ? ViewOrderStatus.PENDING
              : 'Applied'}
          </Styled.TextS>
        </Styled.SummaryRow>
      )}
      <Styled.SummaryGroup $gap>
        {payments.map((payment) => {
          return (
            <Styled.PaymentGroup key={payment.id}>
              <Styled.SummaryRow>
                {getPaymentHeader(payment)}
                <Styled.TextS $weight="bold">
                  {formatCurrency(payment.amount, payment.hasRefund)}
                </Styled.TextS>
              </Styled.SummaryRow>
              <Styled.PaymenySummaryGroup>
                {payment.transactions.map((transaction) => (
                  <Styled.PaymentGroup key={transaction.transaction_id}>
                    <Styled.SummaryRow $isSubRow>
                      <Styled.TextXS $color="onSurfaceC">
                        {transaction.card.type} {transaction.card.number}
                      </Styled.TextXS>
                      <Styled.TextXS>
                        {formatCurrency(
                          transaction?.amount?.value,
                          payment.hasRefund
                        )}
                      </Styled.TextXS>
                    </Styled.SummaryRow>
                  </Styled.PaymentGroup>
                ))}
              </Styled.PaymenySummaryGroup>
            </Styled.PaymentGroup>
          );
        })}
      </Styled.SummaryGroup>

      <Styled.TotalPaidGroup>
        <Styled.SummaryRow>
          <Styled.TextL $weight="bold">
            {orderStatus === ViewOrderStatus.PENDING
              ? 'Estimated Total*'
              : 'Total Paid'}
          </Styled.TextL>
          <Styled.TextL $weight="bold">{formatCurrency(amount)}</Styled.TextL>
        </Styled.SummaryRow>
        <Styled.TextXS>
          (inc. GST){' '}
          {orderStatus !== ViewOrderStatus.PENDING && (
            <Styled.TotalPaidDate>
              {format(max(transactionDates), DateFormats.DAY_MONTH)}
            </Styled.TotalPaidDate>
          )}
        </Styled.TextXS>
      </Styled.TotalPaidGroup>
      {orderStatus === ViewOrderStatus.PENDING && (
        <Styled.PendingMessage>
          *Prices shown are estimates only. While you may see a pending payment
          in your bank account, we'll only charge you the final amount once your
          order is packed.
        </Styled.PendingMessage>
      )}
      <Styled.ReceiptMessage>
        To view your full digital receipt please visit the retailer website
        under account information.
      </Styled.ReceiptMessage>
    </Styled.SummaryWrapper>
  );
};

import { LoadingCard } from '@/components';
import { PaymentMethodEnums } from '@/features/card-management';
import { useGetFlybuysCards } from '@/features/loyalty/services';
import { useHasUserConsentedTerms } from '@/features/profile';
import { isCardErrorTnc } from '@/utils';

import { FlybuysFetchError } from './fetch-error/flybuys-fetch-error';

import { FlybuysLinked, FlybuysNotLinked } from '.';

export const Flybuys = () => {
  const { data, error, isError, isFetching, refetch } = useGetFlybuysCards();
  const consentedTerms = useHasUserConsentedTerms();

  const isGetFlybuysCardsLoading =
    !consentedTerms || isCardErrorTnc(error, isFetching, isError);

  if (isGetFlybuysCardsLoading) {
    return <LoadingCard />;
  }

  if (isError) {
    return <FlybuysFetchError />;
  }

  const linkedCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.LINKED
  );

  const hasExpiredRefreshToken = !!linkedCard && !linkedCard?.live?.status;

  const pendingLinkCard = data?.cards?.find(
    (card) => card.status === PaymentMethodEnums.LoyaltyCardStatus.PENDING_LINK
  );

  if (linkedCard && !hasExpiredRefreshToken) {
    return <FlybuysLinked card={linkedCard} reloadLoyaltyCardList={refetch} />;
  }

  return (
    <FlybuysNotLinked
      hasExpiredRefreshToken={hasExpiredRefreshToken}
      linkedCard={linkedCard}
      pendingLinkCard={pendingLinkCard}
      reloadLoyaltyCardList={refetch}
    />
  );
};

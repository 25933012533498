import flow from 'lodash.flow';

import { sortOrderPayments } from '@/features/activity/utils/sort-payments';

import { groupOrdersByDate, removeAbortedNonRefundOrders } from '../utils';
import { sortOrdersByDate } from '../utils/sort-orders-by-date';
import { transformOrdersData } from '../utils/transform-orders-data';

import type { OrderServiceTypes } from './typings';
import type { OrderTypes } from '../types';
import type { InfiniteData } from '@tanstack/react-query';

export const select = {
  filterOrders(
    query: InfiniteData<OrderServiceTypes.TOrdersList>
  ): OrderServiceTypes.TOrder[] {
    return removeAbortedNonRefundOrders(
      query?.pages?.flatMap((page) => page?.orders)
    );
  },
  formatOrders(orders: OrderServiceTypes.TOrder[]): OrderTypes.TOrdersList {
    const formattedOrders = flow<
      OrderServiceTypes.TOrder[][],
      OrderTypes.TOrderInfo[] | undefined,
      OrderTypes.TOrderInfo[] | undefined,
      OrderTypes.TOrderInfo[] | undefined
    >(
      transformOrdersData,
      sortOrdersByDate,
      sortOrderPayments
    )(orders);
    return {
      groupedOrders: groupOrdersByDate(formattedOrders),
      orders: formattedOrders,
    };
  },
};

import { useContext } from 'react';

import { createStoreSelectors } from '../create-selectors';

import { CardStoreContext } from './card-store-context';

export const useCardStore = () => {
  const store = useContext(CardStoreContext);

  if (!store) {
    throw new Error('Missing AddCardStoreProvider');
  }

  return createStoreSelectors(store);
};

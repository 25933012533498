import { useAuth } from 'react-oidc-context';

import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

import { transformGetBankCards } from './transform-get-bank-cards';

import type { PaymentMethodTypes } from '@/features/card-management';

export function useGetBankCardsRequest() {
  const auth = useAuth();

  return async function getBankCardsRequest() {
    const response =
      await request<PaymentMethodTypes.TPaymentMethodsBankCardsResponse>(
        `${endpoints.BANK_CARDS}`,
        auth,
        { method: RequestMethod.GET },
        {
          priority: EventPriority.P2,
          requestKey: PaymentMethodEndpointKeys.BANK_CARDS,
          transaction: paymentMethodTransactionKeys.bankCards,
        }
      );

    return transformGetBankCards(response);
  };
}

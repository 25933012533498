import { useAuth } from 'react-oidc-context';

import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

export function useDeleteCardRequest(cardId: string) {
  const auth = useAuth();

  return async function deleteCardRequest() {
    return await request(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        method: RequestMethod.DELETE,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_DELETE,
        transaction: paymentMethodTransactionKeys.bankCardDelete,
      }
    );
  };
}

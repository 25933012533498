import styled from 'styled-components';

const AddressListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
`;

const Container = styled.div`
  padding: var(--space-xl) var(--space-l);
  background-color: var(--colors-surfaceLowest);
  border-radius: var(--radii-xl);
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 480px;
  width: 100%;
`;

const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  max-width: 430px;
`;

const EmptyListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);

  h3 {
    font-size: var(--fontSizes-16);
    font-weight: var(--fontWeights-bold);
  }

  p {
    font-size: var(--fontSizes-14);
    font-weight: var(--fontWeights-normal);
    color: var(--colors-onSurfaceB);
  }
`;

const Heading = styled.h2`
  font-size: var(--fontSizes-20);
  line-height: 120%;
  color: var(--colors-onSurfaceA);
  margin: 0;
  font-weight: var(--fontWeights-bold);
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-l);
`;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 480px;
`;

const AddressDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddressDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const AddressDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
`;

const Actions = styled.div`
  border-top: 1px solid #807f80;
  padding-top: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const TextLabel = styled.p`
  color: var(--colors-onSurfaceC);
  font-size: var(--fontSizes-14);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 120%;
  margin: 0;
`;

const TextValue = styled.p<{ $color?: string }>`
  color: ${(props) => props.$color || 'var(--colors-onSurfaceB)'};
  font-size: var(--fontSizes-16);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 120%;
  margin: 0;
`;

const AddressDetailsColumn = styled.div<{ $width?: string }>`
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  width: ${(props) => props.$width || '50%'};
`;

const DefaultAddressPill = styled.div`
  display: flex;
  padding: var(--space-xs) var(--space-s);
  justify-content: center;
  align-items: center;
  gap: var(--space-2xs);
  font-size: var(--fontSizes-14);
  font-style: normal;
  font-weight: var(--fontWeights-normal);
  line-height: 120%;
  background: var(--colors-brand1Lowest);
  color: var(--colors-onBrand1Low);
  border-radius: 120px;
  width: 70px;
`;

const DefaultAddressColumn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Grid = styled.div<{ $noJustify?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$noJustify ? 'unset' : 'space-between')};
`;

const FormRow = styled.div`
  display: flex;
  gap: 20;
`;

const ErrorMessageWrapper = styled.div`
  color: var(--colors-onErrorLow);
  margin: 10px 0;
`;

const DescriptionWrapper = styled.div`
  color: var(--colors-onSurfaceB);
  margin-top: var(--space-2xs);

  a {
    font-weight: var(--fontWeights-normal);
    color: var(--colors-onSurfaceB);
  }
`;

export const Styled = {
  Actions,
  AddressDetails,
  AddressDetailsColumn,
  AddressDetailsContainer,
  AddressDetailsWrapper,
  AddressListWrapper,
  Container,
  DefaultAddressColumn,
  DefaultAddressPill,
  DescriptionWrapper,
  EmptyList,
  EmptyListTextWrapper,
  ErrorMessageWrapper,
  FormRow,
  Grid,
  Heading,
  HeadingWrapper,
  SpinnerWrapper,
  TextLabel,
  TextValue,
};

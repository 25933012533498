import { BaasErrors, type TBaasErrorResponse } from '@/services';
import { CardDialogErrorStates, useCardStore } from '@/store';

import type { TBaasAddPaymentMethodErrors } from '../../types';

export function useAuthorizeCardError() {
  const { setErrorDialogState } = useCardStore().use.actions();

  return function addCardAuthorizeErrorCallback(
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) {
    error?.details?.error_number?.toString() === BaasErrors.MAX_CARDS_LIMIT
      ? setErrorDialogState(CardDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED)
      : setErrorDialogState(CardDialogErrorStates.BANK_CARD_ADD_ERROR);
  };
}

import { useQueryClient } from '@tanstack/react-query';

import { Layout, Panel, SideSheet, Title } from '@/components';
import {
  AddCard,
  BankCards,
  CardDialogError,
  CardDialogSuccess,
  ViewCard,
} from '@/features/card-management';
import { useCardDialogErrorReset } from '@/features/card-management';
import {
  paymentMethodQueryKeys,
  useGetBankCards,
} from '@/features/card-management/services';
import { usePageTitle } from '@/hooks/use-page-title';
import { useCardStore } from '@/store';

import { LoyaltyCardPanel } from './loyalty-card';
import { Styled } from './styles';

export const Wallet = () => {
  usePageTitle('Wallet');

  const getBankCards = useGetBankCards();

  const handleOpenChange = useCardDialogErrorReset();

  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const isAddCardOpen = useCardStore().use.isAddCardOpen();
  const isEditingCard = useCardStore().use.isEditingCard();
  const isEditingNickname = useCardStore().use.isEditingNickname();
  const isViewCardOpen = useCardStore().use.isViewCardOpen();
  const selectedCard = useCardStore().use.selectedCard();

  const {
    setIs3dsIframeOpen,
    setIsAddCardOpen,
    setIsEditingCard,
    setIsEditingNickname,
    setIsViewCardOpen,
  } = useCardStore().use.actions();

  const queryClient = useQueryClient();

  const handleAddCardClose = () => {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.allCardsBank,
    });

    setIsAddCardOpen(false);
    setIs3dsIframeOpen(false);
  };

  const handleViewCardClose = () => {
    setIs3dsIframeOpen(false);
    setIsViewCardOpen(false);
    setIsEditingNickname(false);
    setIsEditingCard(false);
  };

  const viewCardSideSheetTitle = (): string => {
    if (isEditingNickname) {
      return 'Edit nickname';
    }

    if (isEditingCard) {
      return 'Edit payment card';
    }

    return 'Card details';
  };

  const isEditing = isEditingCard || isEditingNickname;
  const is3DSOpen = is3dsIframeOpen || is3dsIframeOpen;

  const refocusSidesheetState = is3DSOpen
    ? 'is3DS'
    : isEditing
      ? 'isEditing'
      : '';

  return (
    <Layout>
      <Title title="Your wallet" />
      <Styled.Container>
        <Styled.Column>
          <BankCards />
        </Styled.Column>

        <Styled.Column>
          <Panel title="Memberships">
            <LoyaltyCardPanel />
          </Panel>
        </Styled.Column>
      </Styled.Container>

      <SideSheet
        onClose={handleAddCardClose}
        open={isAddCardOpen}
        title="Add a debit or credit card"
      >
        <AddCard />
      </SideSheet>

      <SideSheet
        onClose={handleViewCardClose}
        open={isViewCardOpen}
        refocusState={refocusSidesheetState}
        title={viewCardSideSheetTitle()}
      >
        {!!selectedCard && (
          <ViewCard
            card={selectedCard}
            cards={getBankCards?.data?.cards}
            handleViewCardClose={handleViewCardClose}
          />
        )}
      </SideSheet>

      <CardDialogSuccess />
      <CardDialogError handleOpenChange={handleOpenChange} />
    </Layout>
  );
};

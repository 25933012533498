import type {
  TCardDialogErrorStates,
  TCardDialogSuccessStates,
  TCardStoreSet,
} from './card-store-types';
import type { PaymentMethodTypes } from '@/features/card-management';

export const addCardStoreActions = (set: TCardStoreSet) => ({
  setEditCardErrorMessage: (editCardErrorMessage: string | null) =>
    set({ editCardErrorMessage }),
  setEditCardErrorTitle: (editCardErrorTitle: string | null) =>
    set({ editCardErrorTitle }),
  setErrorDialogState: (errorDialogState: TCardDialogErrorStates) =>
    set({ errorDialogState }),
  setFinalizePayload: (
    finalizePayload: PaymentMethodTypes.TAddCard3dsFinalizeRequest | undefined
  ) => set({ finalizePayload }),
  setIs3dsIframeOpen: (is3dsIframeOpen: boolean) => set({ is3dsIframeOpen }),
  setIsAddCardOpen: (isAddCardOpen: boolean) => set({ isAddCardOpen }),
  setIsAddCardPending: (isAddCardPending: boolean) => set({ isAddCardPending }),
  setIsCardLimitReachedOpen: (isCardLimitReachedOpen: boolean) =>
    set({ isCardLimitReachedOpen }),
  setIsCardSuccessOpen: (isCardSuccessOpen: boolean) =>
    set({ isCardSuccessOpen }),
  setIsEditingCard: (isEditingCard: boolean) => set({ isEditingCard }),
  setIsEditingNickname: (isEditingNickname: boolean) =>
    set({ isEditingNickname }),
  setIsOpenCardList: (isOpenCardList: boolean) => set({ isOpenCardList }),
  setIsOpenDeleteDialog: (isOpenDeleteDialog: boolean) =>
    set({ isOpenDeleteDialog }),
  setIsOpenErrorDialog: (isOpenErrorDialog: boolean) =>
    set({ isOpenErrorDialog }),
  setIsViewCardOpen: (isViewCardOpen: boolean) => set({ isViewCardOpen }),
  setSelectedCard: (selectedCard: PaymentMethodTypes.TBankCard | undefined) =>
    set({ selectedCard }),
  setSuccessDialogState: (successDialogState: TCardDialogSuccessStates) =>
    set({ successDialogState }),
  setThreeDSecureId: (threeDSecureId: string) => set({ threeDSecureId }),
});

import flow from 'lodash.flow';

import { appendCard } from './append-card';

import type { PaymentMethodTypes } from '@/features/card-management/types';

export function appendCardProps(card: PaymentMethodTypes.TBankCard) {
  return flow(
    appendCard.isExpired,
    appendCard.isInvalid,
    appendCard.isUnavailable,
    appendCard.isValidated,
    appendCard.isVerified
  )(card);
}

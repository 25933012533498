import { Form } from '@/components';

import { EditCardFormBody } from './edit-card-form-body';
import { EditCardFormFooter } from './edit-card-form-footer';
import { useEditCardDFormInit } from './use-edit-card-form-init';
import { useEditCardFormSubmit } from './use-edit-card-form-submit';

import type { TEditCardForm } from './edit-card-form-types';

export function EditCardForm({
  card,
  isProcessing,
  updateCard,
}: TEditCardForm) {
  const form = useEditCardDFormInit(card);
  const handleSubmitForm = useEditCardFormSubmit(updateCard);

  return (
    <Form config={form} onSubmitForm={handleSubmitForm}>
      <EditCardFormBody card={card} isProcessing={isProcessing} />
      <EditCardFormFooter isProcessing={isProcessing} />
    </Form>
  );
}

import flow from 'lodash.flow';

import { getOrderUtils } from './get-order-info.utils';

import type { OrderServiceTypes } from '@/features/activity/services';
import type { OrderTypes } from '@/features/activity/types';

export function transformOrdersData(
  orders?: OrderServiceTypes.TOrder[]
): OrderTypes.TOrderInfo[] | undefined {
  return orders?.map((order) =>
    flow(
      getOrderUtils.getMerchantDetails,
      getOrderUtils.transformPayments,
      getOrderUtils.hasRefund,
      getOrderUtils.getOrderDate,
      getOrderUtils.hasMycoles,
      getOrderUtils.getMerchantLocation,
      getOrderUtils.getOrderAmount,
      getOrderUtils.getOrderStatus,
      getOrderUtils.cleanOrder
    )(order.details?.order)
  );
}

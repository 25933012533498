import { Divider } from '@b707/ponyta';

import { ButtonSpinner } from '@/components';

import styles from './edit-card-nickname-footer.module.css';

import type { TEditCardNicknameFormFooter } from './edit-card-nickname-footer-types';

export function EditCardNicknameFormFooter({
  updateCardNickname,
}: TEditCardNicknameFormFooter) {
  return (
    <div className={styles['edit-card-nickname-footer']}>
      <Divider />

      <ButtonSpinner
        disabled={updateCardNickname.isPending}
        level="primary"
        loading={updateCardNickname.isPending}
        size="medium"
        spinnerColor={'var(--colors-onSurfaceC)'}
        variant="branded"
      >
        Save
      </ButtonSpinner>
    </div>
  );
}

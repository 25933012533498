import { BaasErrors, type TBaasErrorResponse } from '@/services';
import { CardDialogErrorStates, useCardStore } from '@/store';

import type { TBaasAddPaymentMethodErrors } from '../../types';

export function useAddCardFinalizeError() {
  const {
    setErrorDialogState,
    setIsAddCardPending,
    setIsCardLimitReachedOpen,
  } = useCardStore().use.actions();

  return function addCardFinalizeError(
    error: TBaasErrorResponse<TBaasAddPaymentMethodErrors>
  ) {
    setIsAddCardPending(false);

    error?.details?.error_number?.toString() === BaasErrors.MAX_CARDS_LIMIT
      ? setIsCardLimitReachedOpen(true)
      : setErrorDialogState(CardDialogErrorStates.BANK_CARD_ADD_ERROR);
  };
}

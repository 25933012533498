import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import styles from './dialog-header.module.css';

import type { PropsWithChildren } from 'react';

type TDialogHeaderProps = PropsWithChildren<{
  visuallyHidden?: boolean;
}>;

export function DialogHeader({ children, visuallyHidden }: TDialogHeaderProps) {
  return visuallyHidden ? (
    <VisuallyHidden>{children}</VisuallyHidden>
  ) : (
    <div className={styles['dialog-header']}>{children}</div>
  );
}

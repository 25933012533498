import { camelize } from 'inflection';

import type { QueryKey } from '@tanstack/react-query';

function camelCaseKeys(keys: string[]): string {
  return camelize(keys.join('_'), true);
}

export function queryKeyFactory(key: Record<string, unknown>) {
  const result: Record<string, QueryKey> = {};

  function traverseNested<T extends string>(
    nested: object[] | string[],
    key: T,
    parentKey: string[] = []
  ) {
    nested.forEach((subKey) => {
      if (subKey instanceof Object) {
        // continue traversing nested objects
        traverse(subKey as Record<string, unknown>, [...parentKey, key]);
      } else {
        // We've reached a end node
        result[camelCaseKeys([...parentKey, key, subKey])] = [
          ...parentKey,
          key,
          subKey,
        ];
      }
    });
  }

  function traverse(obj: Record<string, unknown>, parentKey: string[] = []) {
    Object.keys(obj).forEach((k) => {
      if (typeof obj[k] === 'string') {
        // No need to prefix 'all' for end nodes
        result[camelCaseKeys([...parentKey, k])] = [...parentKey, k];
      } else {
        result[camelCaseKeys(['all', ...parentKey, k])] = [...parentKey, k];
        if (Array.isArray(obj[k])) {
          // continue traversing nested array
          traverseNested(obj[k] as object[] | string[], k, parentKey);
        } else {
          // continue traversing nested objects
          traverse(obj[k] as Record<string, unknown>, [...parentKey, k]);
        }
      }
    });
  }
  traverse(key);

  return result;
}

import { Button } from '@/components';
import { TrashIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';
import { useCardStore } from '@/store';

import styles from './view-card-footer.module.css';

export function ViewCardFooterDeleteCardButton() {
  const { track } = useAnalytics();
  const { setIsOpenDeleteDialog } = useCardStore().use.actions();

  const handleClickRemove = () => {
    track('Delete Card Started');
    setIsOpenDeleteDialog(true);
  };

  return (
    <Button
      className={styles['action-button']}
      level="tertiary"
      onClick={handleClickRemove}
      size="medium"
      variant="destructive"
    >
      <TrashIcon />
      Remove
    </Button>
  );
}

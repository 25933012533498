import { V2QrEnums } from '@/services';

import type { OrderServiceTypes } from '@/features/activity/services';

/**
 * Filters out any aborted orders with no refunds
 */
export function removeAbortedNonRefundOrders(
  orders?: OrderServiceTypes.TOrder[]
) {
  if (!orders) {
    return [];
  }
  return orders.filter(
    (order) =>
      order.status !== V2QrEnums.OrderStatusV2.ABORTED ||
      order.details?.order?.payments?.some((payment) => payment.is_refund)
  );
}

import { joiResolver } from '@hookform/resolvers/joi';
import { useQueryClient } from '@tanstack/react-query';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ButtonSpinner,
  Checkbox,
  ErrorDialog,
  Form,
  TextField,
} from '@/components';
import { genericContent } from '@/config/language';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_UPDATE_PROFILE_GENERIC,
} from '@/config/language/errors';
import { useTheme } from '@/contexts/theme';
import {
  MAX_CHARS_PROFILE_NAME,
  MIN_CHARS_DEFAULT,
  getMarketingConsent,
  getTermsConsent,
  useGetProfile,
  useUpdateProfile,
} from '@/features/profile';
import { validate } from '@/utils';

import {
  DetailsFormFieldNames,
  DetailsFormLabels,
  DetailsFormPlaceholders,
  TERMS_AND_CONDITIONS_LABEL,
} from './copy';
import { Styled } from './styles';

import type { FieldValues } from 'react-hook-form';

const DEFAULT_FORM_VALUES = {
  [DetailsFormFieldNames.FIRST_NAME]: '',
  [DetailsFormFieldNames.LAST_NAME]: '',
  [DetailsFormFieldNames.TERMS_AND_CONDITIONS]: false,
  [DetailsFormFieldNames.MARKETING_CONSENT]: false,
} as FieldValues;

export const DetailsForm = () => {
  const queryClient = useQueryClient();

  const { theme } = useTheme();
  const [isError, setIsError] = useState<boolean>(false);

  const getProfile = useGetProfile();

  const validationSchema = Joi.object({
    [DetailsFormFieldNames.FIRST_NAME]: validate.firstName(),
    [DetailsFormFieldNames.LAST_NAME]: validate.lastName(),
    [DetailsFormFieldNames.TERMS_AND_CONDITIONS]: validate.terms(),
    [DetailsFormFieldNames.MARKETING_CONSENT]: validate.marketingConsent(),
  }).unknown(true);

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
    shouldFocusError: false,
  });

  const updateProfile = useUpdateProfile({
    onError: () => {
      setIsError(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const isSubmitting = updateProfile.isPending;

  const handleSubmit = ({
    firstName,
    lastName,
    marketingConsent,
    termsAndConditions,
  }: FieldValues) => {
    const terms = getTermsConsent(getProfile.data, !!termsAndConditions);
    const marketing = getMarketingConsent(getProfile.data, !!marketingConsent);

    updateProfile.mutate({
      consents: [
        ...(!!terms ? [terms] : []),
        ...(!!marketing && !!marketingConsent ? [marketing] : []),
      ],
      first_name: firstName.trim(),
      last_name: lastName.trim(),
    });
  };

  useEffect(() => {
    getProfile.data?.first_name &&
      formMethods.setValue(
        DetailsFormFieldNames.FIRST_NAME,
        getProfile?.data.first_name
      );
    getProfile.data?.last_name &&
      formMethods.setValue(
        DetailsFormFieldNames.LAST_NAME,
        getProfile?.data.last_name
      );
  }, [formMethods, getProfile]);

  useEffect(() => {
    if (!!formMethods.formState.errors) {
      formMethods.setFocus(Object.keys(formMethods.formState.errors)[0], {
        shouldSelect: true,
      });
    }
  }, [formMethods, formMethods.formState.errors]);

  return (
    <Form config={formMethods} onSubmitForm={handleSubmit}>
      <Styled.Fieldset>
        <Styled.NameFieldWrapper>
          <TextField
            autocomplete="given-name"
            disabled={isSubmitting}
            label={DetailsFormLabels.FIRST_NAME}
            maxLength={MAX_CHARS_PROFILE_NAME}
            minLength={MIN_CHARS_DEFAULT}
            name={DetailsFormFieldNames.FIRST_NAME}
            placeholder={DetailsFormPlaceholders.FIRST_NAME}
            required
          />
          <TextField
            autocomplete="family-name"
            disabled={isSubmitting}
            label={DetailsFormLabels.LAST_NAME}
            maxLength={MAX_CHARS_PROFILE_NAME}
            minLength={MIN_CHARS_DEFAULT}
            name={DetailsFormFieldNames.LAST_NAME}
            placeholder={DetailsFormPlaceholders.LAST_NAME}
            required
          />
        </Styled.NameFieldWrapper>

        <Styled.CheckboxWrapper>
          <Checkbox
            disabled={isSubmitting}
            id={DetailsFormFieldNames.TERMS_AND_CONDITIONS}
            label={TERMS_AND_CONDITIONS_LABEL(theme)}
            required
            {...formMethods.register(
              `${DetailsFormFieldNames.TERMS_AND_CONDITIONS}`
            )}
          />
        </Styled.CheckboxWrapper>

        <Checkbox
          disabled={isSubmitting}
          id={DetailsFormFieldNames.MARKETING_CONSENT}
          label={DetailsFormLabels.MARKETING_CONSENT}
          {...formMethods.register(
            `${DetailsFormFieldNames.MARKETING_CONSENT}`
          )}
        />
        <ButtonSpinner
          disabled={isSubmitting}
          level={'primary'}
          loading={isSubmitting}
          size="medium"
          spinnerColor={'var(--colors-onSurfaceC)'}
          type="submit"
        >
          Continue
        </ButtonSpinner>
      </Styled.Fieldset>

      <ErrorDialog
        buttonText={BUTTON_TEXT_TRY_AGAIN}
        isOpen={isError}
        message={ERROR_UPDATE_PROFILE_GENERIC}
        onOpenChange={() => setIsError(false)}
        title={genericContent.ERROR_GENERIC_TITLE}
      />
    </Form>
  );
};

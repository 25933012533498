import { CardStatusPill } from '@/components';
import { useCardStore } from '@/store';

import { cardManagementContent } from '../../language';
import { CardWarning } from '../card-warning';
import { EditCardNickname } from '../edit-card-nickname/edit-card-nickname';
import { ViewCardDisplay } from '../view-card-display/view-card-display';

import styles from './view-card-body.module.css';

export function ViewCardBody() {
  const selectedCard = useCardStore().use.selectedCard();

  const isDefault = !!selectedCard?.is_default_pay;
  const isVerified = !!selectedCard?.is_verified;

  const shouldShowPill = isDefault || !isVerified;

  return (
    <div className={styles['card-details-container']}>
      <ViewCardDisplay />

      <div className={styles['card-details']} role="list">
        <div className={styles['card-details-row']}>
          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>
              Card number
            </div>
            <p className={styles['card-details-column-text']}>
              {' '}
              ••• {selectedCard?.data.visualization.last_four_digits}
            </p>
          </div>

          <div className={styles['card-details-column']} role="listitem">
            <div className={styles['card-details-column-label']}>Expiry</div>
            <p className={styles['card-details-column-text']}>
              {selectedCard?.data.expiry.month}/
              {selectedCard?.data.expiry.year.slice(-2)}
            </p>
          </div>

          <div className={styles['card-details-column']} role="listitem">
            {shouldShowPill && (
              <CardStatusPill
                isDefault={isDefault}
                status={selectedCard!.status}
              />
            )}
          </div>
        </div>

        <EditCardNickname />
      </div>

      {(!!selectedCard?.is_validated || !!selectedCard?.is_invalid) && (
        <CardWarning
          text={cardManagementContent.WHY_NOT_DELETE_MESSAGE()}
          title={cardManagementContent.WHY_NOT_DELETE_TITLE}
        />
      )}
    </div>
  );
}

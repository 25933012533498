import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { ButtonLevels, ButtonVariants } from './button-enums';
import { buttonVariants } from './button-variants';

import type { TButtonLevel, TButtonSize, TButtonVariant } from './button-types';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  level?: TButtonLevel;
  size?: TButtonSize;
  variant?: TButtonVariant;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  function Button(
    {
      children,
      className,
      level = ButtonLevels.PRIMARY,
      size,
      variant = ButtonVariants.NEUTRAL,
      ...props
    },
    ref
  ) {
    const classNames = clsx(
      buttonVariants({ className, level, size, variant })
    );

    return (
      <button className={classNames} ref={ref} {...props}>
        {children}
      </button>
    );
  }
);

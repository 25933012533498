import { ConfirmationDialog } from '@/components';
import {
  ERROR_DELETE_CARD_GENERIC,
  ERROR_TITLE_DELETE_CARD,
} from '@/config/language/errors';
import { PaymentMethodEnums } from '@/features/card-management';
import { useSetDefaultCard } from '@/features/card-management/services';
import { useAnalytics } from '@/hooks';
import { SuccessDialogStates } from '@/pages/wallet/enums';
import { useCardStore } from '@/store';

import { useCardShowSuccessDialog } from '../../hooks/use-card-show-success-dialog';
import { useDeleteCard } from '../../services/use-delete-card/use-delete-card';
import { CardListDialog } from '../card-list-dialog/card-list-dialog';
import { EditCard } from '../edit-card/edit-card';
import { EditCardNicknameForm } from '../edit-card-nickname-form/edit-card-nickname-form';
import { ViewCardBody } from '../view-card-body/view-card-body';
import { ViewCardFooter } from '../view-card-footer/view-card-footer';

import styles from './view-card.module.css';

import type { PaymentMethodTypes } from '@/features/card-management';

interface ICardEditor {
  card: PaymentMethodTypes.TBankCard;
  cards?: PaymentMethodTypes.TBankCard[];
  handleViewCardClose: () => void;
}

// CardEditor
export function ViewCard({ cards, handleViewCardClose }: ICardEditor) {
  const { track } = useAnalytics();

  const isEditingCard = useCardStore().use.isEditingCard();
  const isEditingNickname = useCardStore().use.isEditingNickname();
  const isOpenCardList = useCardStore().use.isOpenCardList();
  const isOpenDeleteDialog = useCardStore().use.isOpenDeleteDialog();
  const selectedCard = useCardStore().use.selectedCard();

  const {
    setEditCardErrorMessage,
    setEditCardErrorTitle,
    setIsOpenCardList,
    setIsOpenDeleteDialog,
    setIsOpenErrorDialog,
  } = useCardStore().use.actions();

  const showSuccessDialog = useCardShowSuccessDialog();

  const deletableCardList = cards?.filter(
    (card) =>
      !card.is_default_pay &&
      card.status === PaymentMethodEnums.BankCardStatus.VERIFIED
  );

  const deleteCard = useDeleteCard(selectedCard?.id || '', {
    onError: () => {
      onDeleteError();
    },
    onSuccess: () => {
      onDeleteSuccess();
    },
  });

  const onDeleteSuccess = () => {
    handleViewCardClose();
    setIsOpenDeleteDialog(false);
    setIsOpenCardList(false);
    showSuccessDialog(SuccessDialogStates.DELETE);
  };

  const onDeleteError = () => {
    setEditCardErrorTitle(ERROR_TITLE_DELETE_CARD);
    setEditCardErrorMessage(ERROR_DELETE_CARD_GENERIC);
    setIsOpenErrorDialog(true);
  };

  const onCloseCardListDialog = () => setIsOpenCardList(false);

  const setDefaultCardBeforeDelete = useSetDefaultCard(
    (deletableCardList && deletableCardList[0]?.id) || '',
    {
      onSuccess: () => {
        deleteCard?.mutate();
      },
    }
  );

  const handleDeleteCard = () => {
    track('Delete Card Confirmed');

    if (!!selectedCard?.is_default_pay) {
      if (!deletableCardList?.length) {
        deleteCard?.mutate();
      } else if (deletableCardList?.length === 1) {
        setDefaultCardBeforeDelete.mutate();
      } else {
        setIsOpenCardList(true);
        setIsOpenDeleteDialog(false);
      }
    } else {
      deleteCard?.mutate();
    }
  };

  if (isEditingCard && !!selectedCard) {
    return <EditCard />;
  }

  if (isEditingNickname && !!selectedCard) {
    return <EditCardNicknameForm />;
  }

  return (
    <div className={styles['view-card']}>
      <ConfirmationDialog
        acceptButtonText="Yes, remove it"
        isDanger
        isOpen={isOpenDeleteDialog}
        loading={deleteCard.isPending}
        onClickAccept={handleDeleteCard}
        onClickCancel={() => setIsOpenDeleteDialog(false)}
        title="Are you sure you want to remove this card?"
      />

      <CardListDialog
        cardId={selectedCard!.id}
        cards={deletableCardList}
        onCloseDialog={onCloseCardListDialog}
        onDeleteError={onDeleteError}
        onDeleteSuccess={onDeleteSuccess}
        open={isOpenCardList}
      />

      <ViewCardBody />
      <ViewCardFooter />
    </div>
  );
}

import { useEffect } from 'react';

import { useCardStore } from '@/store';

import type { UseFormReturn } from 'react-hook-form';

export function useAddCardFormReset(form: UseFormReturn) {
  const isAddCardOpen = useCardStore().use.isAddCardOpen();
  const setFinalizePayload = useCardStore().use.actions().setFinalizePayload;

  useEffect(() => {
    form.reset();

    if (!isAddCardOpen) {
      setFinalizePayload(undefined);
    }
  }, [form, isAddCardOpen, setFinalizePayload]);
}

const formatter = new Intl.NumberFormat('en-AU', {
  currency: 'AUD',
  style: 'currency',
});

export const formatCurrency = (value?: number | string, refund?: boolean) => {
  if (!value && value !== 0) {
    return;
  }
  let parsedValue = 0;
  if (typeof value === 'string') {
    parsedValue = parseFloat(value);
  } else {
    parsedValue = value;
  }
  return formatter.format(refund ? parsedValue * -1 : parsedValue);
};

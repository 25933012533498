import { PaymentCard } from '@b707/ponyta';

import { useCardStore } from '@/store';

import styles from './view-card-display.module.css';

export function ViewCardDisplay() {
  const selectedCard = useCardStore().use.selectedCard();

  return (
    <div className={styles['view-card-display']}>
      <PaymentCard
        alias={selectedCard?.alias || selectedCard?.data.issuer_name}
        cardType={selectedCard?.data.type}
        lastFour={selectedCard?.data.visualization.last_four_digits}
        schemes={selectedCard!.data.schemes}
        size="lg"
      />
    </div>
  );
}

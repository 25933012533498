import { CustomerService } from '@/components';

import styles from './load-more-message.module.css';

type TLoadMoreMessage = {
  hasNextPage?: boolean;
  isError?: boolean;
  isFetchingNextPage?: boolean;
  isLoading?: boolean;
  ordersListLength?: number;
};

export function LoadMoreMessage({
  hasNextPage,
  isError,
  isFetchingNextPage,
  isLoading,
  ordersListLength,
}: TLoadMoreMessage) {
  if (isLoading || isFetchingNextPage || !ordersListLength) {
    return null;
  }
  if (isError) {
    return (
      <div className={styles['load-more-messages']}>
        There was an error fetching more records, please try again or{' '}
        <CustomerService />.
      </div>
    );
  }
  if (!hasNextPage) {
    return (
      <div className={styles['load-more-messages']}>No more items to show</div>
    );
  }
}

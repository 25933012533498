import { CardDialogErrorStates, useCardStore } from '@/store';

import { formatDate } from '../../utils/format-date';

import type { TUseAuthorizeCardMutation } from '../../services/use-authorize-card/use-authorize-card-types';
import type { PaymentMethodTypes } from '../../types';
import type { FieldValues } from 'react-hook-form';

export function useAddCardFormSubmit(
  authorizeCard: TUseAuthorizeCardMutation,
  publicKey: PaymentMethodTypes.TPublicKey | undefined
) {
  const { setErrorDialogState, setIsAddCardPending } =
    useCardStore().use.actions();

  return async ({
    cardAlias,
    cardExpiryDate,
    cardNumber,
    cardSecurityCode,
  }: FieldValues) => {
    const [expiryMonth, expiryYear] = formatDate(cardExpiryDate);

    if (!expiryMonth || !expiryYear) {
      return;
    }

    if (!publicKey?.key_id || !publicKey?.public_key) {
      setErrorDialogState(CardDialogErrorStates.BANK_CARD_ADD_ERROR);
      return;
    }

    setIsAddCardPending(true);

    authorizeCard.mutate({
      data: {
        alias: cardAlias,
        cvv: cardSecurityCode,
        expiry_month: expiryMonth,
        expiry_year: expiryYear,
        number: cardNumber.replaceAll(' ', ''),
      },
      publicKeyDetails: publicKey,
    });
  };
}

import { ExclamationMarkIcon } from '@/components/icons';
import { genericContent } from '@/config/language';
import {
  BUTTON_TEXT_TRY_AGAIN,
  ERROR_SAVE_CARD_GENERIC,
  ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT,
  ERROR_TITLE_SAVE_CARD,
  ERROR_UPDATE_CARD_NICKNAME,
  TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
} from '@/config/language/errors';
import { CardDialogErrorStates, useCardStore } from '@/store';

export const errorsBankCards = {
  [CardDialogErrorStates.BANK_CARD_ADD_ERROR]() {
    return {
      buttonText: BUTTON_TEXT_TRY_AGAIN,
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: ERROR_SAVE_CARD_GENERIC,
      title: ERROR_TITLE_SAVE_CARD,
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_COUNT_LIMIT_REACHED]() {
    return {
      buttonText: 'OK',
      isShowTryAgainMessage: undefined,
      message:
        'You can store a maximum of 5 cards in your wallet. Delete a card first if you want to add a new one.',
      title: 'Card limit reached',
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_DELETE_ERROR]() {
    return {
      buttonText: 'OK',
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: '',
      title: '',
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_EDIT_ERROR]() {
    const editCardErrorMessage = useCardStore().getState().editCardErrorMessage;
    const editCardErrorTitle = useCardStore().getState().editCardErrorTitle;

    return {
      buttonText: undefined,
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: false,
      message: editCardErrorMessage || ERROR_SAVE_CARD_GENERIC,
      title: editCardErrorTitle || genericContent.ERROR_GENERIC_TITLE,
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_EDIT_NICKNAME_ERROR]() {
    return {
      buttonText: undefined,
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: ERROR_UPDATE_CARD_NICKNAME,
      title: genericContent.ERROR_GENERIC_TITLE,
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR]() {
    const selectedCard = useCardStore().getState().selectedCard;
    const status = selectedCard?.status;

    return {
      buttonText: 'Continue',
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: `Your card is ${status} and cannot be set as default. Please select a
  different card and choose “Set as default card”.`,
      title: `Can't set ${status} card as default`,
      tryAgainMessage: '',
    };
  },

  [CardDialogErrorStates.BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR]() {
    return {
      buttonText: 'Continue',
      icon: <ExclamationMarkIcon />,
      isShowTryAgainMessage: undefined,
      message: <ERROR_SET_DEFAULT_CARD_ALREADY_DEFAULT />,
      title: TITLE_SET_DEFAULT_CARD_ALREADY_DEFAULT,
      tryAgainMessage: '',
    };
  },
};

import { useQueryClient } from '@tanstack/react-query';

import { CardDialogSuccessStates, useCardStore } from '@/store';

import { paymentMethodQueryKeys } from '../../services';

export function useAddCardFinalizeSuccess() {
  const queryClient = useQueryClient();

  const {
    setIs3dsIframeOpen,
    setIsAddCardOpen,
    setIsAddCardPending,
    setIsCardSuccessOpen,
    setSuccessDialogState,
  } = useCardStore().use.actions();

  return function addCardFinalizeCallback() {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.allCardsBank,
    });
    setSuccessDialogState(CardDialogSuccessStates.ADD);
    setIsCardSuccessOpen(true);
    setIsAddCardOpen(false);
    setIs3dsIframeOpen(false);
    setIsAddCardPending(false);
  };
}

import isEmpty from 'lodash.isempty';
import { createContext, useState } from 'react';
import { createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { addCardStoreActions } from './card-store-actions';
import { cardStoreDefaultValues } from './card-store-defaults';

import type {
  ICardStore,
  ICardStoreInitialValues,
  TCardStoreProvider,
} from './card-store-types';

export const CardStoreContext = createContext<ReturnType<
  typeof createAddCardStore
> | null>(null);

export const CardStoreProvider = ({
  children,
  initialValues,
}: TCardStoreProvider) => {
  const initialStoreValue = {
    ...cardStoreDefaultValues,
    ...(!isEmpty(initialValues) && initialValues),
  };

  const [store] = useState(() => createAddCardStore(initialStoreValue));

  return (
    <CardStoreContext.Provider value={store}>
      {children}
    </CardStoreContext.Provider>
  );
};

function createAddCardStore(initialStoreValue: ICardStoreInitialValues) {
  return createStore<ICardStore>()(
    persist(
      (set) => ({
        actions: addCardStoreActions(set),
        ...initialStoreValue,
      }),
      {
        name: 'CardStore',
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => !!['is3dsIframeOpen'].includes(key)
            )
          ),
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
}

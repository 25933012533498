import { useAuth } from 'react-oidc-context';

import { type PaymentMethodTypes } from '@/features/card-management';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '@/features/card-management/services';
import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services/request';

import { endpoints } from '../endpoints';

import type { IUseUpdateCardRequestPayload } from './use-update-card-nickname-types';

export function useUpdateCardNicknameRequest(cardId: string) {
  const auth = useAuth();

  return async function updateCardNicknameRequest(
    payload: IUseUpdateCardRequestPayload
  ) {
    return await request<PaymentMethodTypes.PaymentMethod>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: payload,
        method: RequestMethod.PATCH,
      },
      {
        priority: EventPriority.P3,
        requestKey: PaymentMethodEndpointKeys.CARD_UPDATE_NICKNAME,
        transaction: paymentMethodTransactionKeys.cardUpdateNickname,
      }
    );
  };
}

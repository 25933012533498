import { EventPriority } from '@/features/monitoring';
import { request } from '@/services/request';

import { endpoints } from './endpoints';
import { MerchantEndpointKeys, merchantTransactionKeys } from './query-keys';

import type { OrderServiceTypes } from './typings';
import type { AuthContextProps } from 'react-oidc-context';

type TGetOrdersRequestArgs = {
  auth: AuthContextProps;
  pageParam?: string;
  params?: OrderServiceTypes.TOrdersListRequest;
};

export async function getOrdersRequest({
  auth,
  pageParam,
  params,
}: TGetOrdersRequestArgs) {
  return await request<OrderServiceTypes.TOrdersList>(
    endpoints.qrOrders.ORDERS(),
    auth,
    { params: { ...params, next_key: pageParam } },
    {
      priority: EventPriority.P2,
      requestKey: MerchantEndpointKeys.ORDERS,
      transaction: merchantTransactionKeys.orders,
    }
  );
}

import { ButtonSpinner } from '@/components';
import {
  ERROR_SET_DEFAULT_CARD_FAILED,
  genericContent,
} from '@/config/language';
import { useAnalytics } from '@/hooks';
import {
  CardDialogErrorStates,
  CardDialogSuccessStates,
  useCardStore,
} from '@/store';

import { PaymentMethodEnums } from '../../enums';
import { useCardShowSuccessDialog } from '../../hooks/use-card-show-success-dialog';
import { useViewCardReset } from '../../hooks/use-view-card-reset';
import { useSetDefaultCard } from '../../services';

export function ViewCardFooterSetDefaultCardButton() {
  const viewCardReset = useViewCardReset();
  const showSuccessDialog = useCardShowSuccessDialog();

  const { track } = useAnalytics();

  const selectedCard = useCardStore().use.selectedCard();

  const {
    setEditCardErrorMessage,
    setEditCardErrorTitle,
    setErrorDialogState,
  } = useCardStore().use.actions();

  const setDefaultCard = useSetDefaultCard(selectedCard?.id || '', {
    onError: () => {
      setEditCardErrorTitle(genericContent.ERROR_GENERIC_TITLE);
      setEditCardErrorMessage(ERROR_SET_DEFAULT_CARD_FAILED);
      setErrorDialogState(CardDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR);
    },
    onSuccess: () => {
      viewCardReset();
      showSuccessDialog(CardDialogSuccessStates.SET_DEFAULT);
    },
  });

  const handleSetDefaultCard = () => {
    track('Update Default Card');

    if (selectedCard?.is_default_pay) {
      setErrorDialogState(
        CardDialogErrorStates.BANK_CARD_SET_DEFAULT_ALREADY_DEFAULT_ERROR
      );
    } else if (
      selectedCard?.status !== PaymentMethodEnums.BankCardStatus.VERIFIED
    ) {
      setErrorDialogState(CardDialogErrorStates.BANK_CARD_SET_DEFAULT_ERROR);
    } else {
      setDefaultCard.mutate();
    }
  };

  return (
    <ButtonSpinner
      disabled={setDefaultCard.isPending}
      level="secondary"
      loading={setDefaultCard.isPending}
      onClick={handleSetDefaultCard}
      size="medium"
      spinnerColor={'var(--colors-brand1Highest)'}
      variant="branded"
    >
      Set as default card
    </ButtonSpinner>
  );
}

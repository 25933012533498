import { useCardStore } from '@/store';

import { ViewCardFooterDeleteCardButton } from './view-card-footer-delete-card-button';
import { ViewCardFooterEditCardButton } from './view-card-footer-edit-card-button';
import { ViewCardFooterSetDefaultCardButton } from './view-card-footer-set-default-card-button';
import styles from './view-card-footer.module.css';

export function ViewCardFooter() {
  const selectedCard = useCardStore().use.selectedCard();

  return (
    <div>
      {!selectedCard?.is_invalid && (
        <div className={styles.action}>
          <ViewCardFooterEditCardButton />

          {!selectedCard?.is_validated && (
            <>
              <ViewCardFooterSetDefaultCardButton />
              <ViewCardFooterDeleteCardButton />
            </>
          )}
        </div>
      )}
    </div>
  );
}

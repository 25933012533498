import { ButtonSpinner } from '@/components';

import styles from './edit-card-form-footer.module.css';

import type { TEditCardFormFooter } from './edit-card-form-footer-types';

export function EditCardFormFooter({ isProcessing }: TEditCardFormFooter) {
  return (
    <div className={styles['edit-card-form-footer']}>
      <ButtonSpinner
        disabled={isProcessing}
        level="primary"
        loading={isProcessing}
        size="medium"
        spinnerColor={'var(--colors-onSurfaceC)'}
        variant="branded"
      >
        Save
      </ButtonSpinner>
    </div>
  );
}

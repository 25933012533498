import { OrderRow } from './order-row';
import styles from './orders-table-data.module.css';
import { OrdersTableEmpty } from './orders-table-empty';
import { OrdersTableError } from './orders-table-error';
import { OrdersTableLoading } from './orders-table-loading';

import type { OrderTypes } from '../../types';

export type TOrderTableDataProps = {
  isError: boolean;
  isLoading: boolean;
  onClick?: (id: string) => void;
  orders?: OrderTypes.TSortedOrders[];
};
export function OrdersTableData({
  isError,
  isLoading,
  onClick,
  orders,
}: TOrderTableDataProps) {
  const hasOrders = !!orders?.length;

  if (!isLoading && !hasOrders && isError) {
    return <OrdersTableError />;
  }
  if (!isLoading && !hasOrders) {
    return <OrdersTableEmpty />;
  }
  if (isLoading) {
    return <OrdersTableLoading />;
  }
  return (
    <div className={styles['wrapper']}>
      <div className={styles['table-header']}>
        <div className={styles['header-data']}>Date</div>
        <div className={styles['header-data']}>Merchant</div>
        <div className={styles['header-data']}>Order ID</div>
        <div className={styles['header-data']}>Amount</div>
      </div>
      {!!orders &&
        orders.map((orderGroup) => {
          return (
            <div className={styles['wrapper']} key={orderGroup.create_at}>
              <div className={styles['date-title']}>{orderGroup.create_at}</div>
              {orderGroup.orders.map((order) => {
                return (
                  <OrderRow
                    key={order.orderId}
                    onClick={onClick}
                    order={order}
                  />
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

import { CardInput, FormHint } from '@/components';
import { useCardStore } from '@/store';

import { ViewCardDisplay } from '../view-card-display/view-card-display';

import styles from './edit-card-form-body.module.css';

import type { TEditCardDetailsFormBody } from './edit-card-form-body-types';

export function EditCardFormBody({ isProcessing }: TEditCardDetailsFormBody) {
  const selectedCard = useCardStore().use.selectedCard();

  return (
    <div className={styles['edit-card-details-form-body']}>
      <ViewCardDisplay />

      <div className={styles['card-details']}>
        <FormHint />

        <CardInput
          isEditing
          isSubmitting={isProcessing}
          lastFour={selectedCard?.data?.visualization?.last_four_digits}
          schemes={selectedCard?.data?.schemes}
        />
      </div>
    </div>
  );
}

import { queryKeyFactory } from '@/utils/query-key-factory';

export enum PaymentMethodEndpointKeys {
  BANK_CARDS = 'BANK_CARDS',
  BANK_CARD_ADD_3DS_AUTHORIZE = 'BANK_CARD_ADD_3DS_AUTHORIZE',
  BANK_CARD_ADD_3DS_FINALIZE = 'CARD_ADD_3DS_FINALIZE',
  BANK_CARD_DELETE = 'BANK_CARD_DELETE',
  BANK_CARD_UPDATE_3DS = 'CARD_UPDATE_3DS',
  CARD_UPDATE_DEFAULT = 'CARD_UPDATE_DEFAULT',
  CARD_UPDATE_NICKNAME = 'CARD_UPDATE_NICKNAME',
  PUBLIC_KEY = 'PUBLIC_KEY',
}
export const paymentMethodTransactionKeys = {
  bankCardAdd3DSAuthorize: 'Add Bank Card 3DS Authorize',
  bankCardAdd3DSFinalize: 'Add Bank Card 3DS Finalize',
  bankCardDelete: 'Delete Bank Card',
  bankCardUpdate3DS: 'Update Bank Card 3DS',
  bankCards: 'Get Bank Cards',
  cardUpdateDefault: 'Update Default Card',
  cardUpdateNickname: 'Update Card Nickname',
  publicKey: 'Public Key',
};

export const paymentMethodQueryKeys = queryKeyFactory({
  cards: {
    bank: [
      'get',
      { add: ['authorize', 'finalize'] },
      'delete',
      { update: ['nickname', 'default', 'card'] },
    ],
    flybuys: ['get'],
    publicKey: 'public-key',
  },
});

paymentMethodQueryKeys;

import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { PaymentMethodTypes } from '../../types';

export function useFinalizeCardRequest() {
  const auth = useAuth();

  return async function finalizeCardRequest(
    payload: PaymentMethodTypes.TAddCard3dsFinalizeRequest
  ) {
    return await request(
      endpoints.BANK_CARDS_FINALIZE,
      auth,
      {
        data: {
          authorization_id: payload.authorization_id,
          ...(payload.isDefault && {
            is_default_pay: true,
          }),
          payer: {
            card: {
              id: payload.card_id,
            },
          },
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_ADD_3DS_FINALIZE,
        transaction: paymentMethodTransactionKeys.bankCardAdd3DSFinalize,
      }
    );
  };
}

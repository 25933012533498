import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { generateCardCipherText } from '../../utils';
import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { TAuthorizeCardResponse } from './use-authorize-card-types';
import type { PaymentMethodTypes } from '../../types';

export function useAuthorizeCardRequest() {
  const auth = useAuth();

  return async function authorizeCardRequest(
    payload: PaymentMethodTypes.TAddCardRequest
  ) {
    const cipher_text = await generateCardCipherText({
      data: payload.data,
      publicKeyDetails: payload.publicKeyDetails,
    });

    return await request<TAuthorizeCardResponse>(
      endpoints.BANK_CARDS_AUTHORIZE,
      auth,
      {
        data: {
          ...(payload.data.alias && {
            alias: payload.data.alias,
          }),
          card: {
            cipher_text,
          },
        },
        method: RequestMethod.POST,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_ADD_3DS_AUTHORIZE,
        transaction: paymentMethodTransactionKeys.bankCardAdd3DSAuthorize,
      }
    );
  };
}

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';

import { CardInputFieldNames } from '@/components/card-input';
import { validate } from '@/utils';

import type { PaymentMethodTypes } from '../../types';
import type { FieldValues } from 'react-hook-form';

export function useEditCardDFormInit(card: PaymentMethodTypes.TBankCard) {
  const DEFAULT_FORM_VALUES = {
    [CardInputFieldNames.CARD_EXPIRY_DATE]: '',
    [CardInputFieldNames.CARD_SECURITY_CODE]: '',
  } as FieldValues;

  const validationSchema = Joi.object({
    [CardInputFieldNames.CARD_EXPIRY_DATE]: validate.cardExpiryDate(),
    [CardInputFieldNames.CARD_SECURITY_CODE]: validate.cardSecurityCode(card),
  });

  return useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    reValidateMode: 'onChange',
    resolver: joiResolver(validationSchema),
  });
}

import { appendCardProps } from './append-card-props';
import { sortPaymentCards } from './sort-payment-cards';

import type { PaymentMethodTypes } from '@/features/card-management/types';

export function transformGetBankCards(
  response: PaymentMethodTypes.TPaymentMethodsBankCardsResponse
) {
  const cards = response?.cards?.map((card) => ({
    ...appendCardProps(card),
  })) as PaymentMethodTypes.TBankCard[];

  const sortedCards = sortPaymentCards(cards);

  return {
    ...response,
    cards: sortedCards,
    isExceedMaxNumberOfCards: hasExceededMaxNumberOfCards(
      response?.cards,
      response.metadata.user_bank_cards_limit.max_allowed
    ),
  };
}

function hasExceededMaxNumberOfCards(
  cards: PaymentMethodTypes.TBankCard[],
  maxCardsAllowed: number
) {
  return cards.length >= maxCardsAllowed;
}

import { useQueryClient } from '@tanstack/react-query';

import { useCardStore } from '@/store';

import { paymentMethodQueryKeys } from '../services';

import type { TCardDialogSuccessStates } from '@/store';

export function useCardShowSuccessDialog() {
  const queryClient = useQueryClient();

  const { setIsCardSuccessOpen, setSuccessDialogState } =
    useCardStore().use.actions();

  return function showSuccessDialog(
    successDialogState: TCardDialogSuccessStates
  ) {
    queryClient.invalidateQueries({
      queryKey: paymentMethodQueryKeys.bankCards,
    });

    setSuccessDialogState(successDialogState);
    setIsCardSuccessOpen(true);
  };
}

import { TextField } from '@/components';
import { CardInputFieldNames, CardInputLabels } from '@/components/card-input';
import { MAX_CHARS_CARD_NICKNAME, MIN_CHARS_DEFAULT } from '@/features/profile';

export function EditCardNicknameBody() {
  return (
    <div>
      <TextField
        helpText={`Nickname must be between ${MIN_CHARS_DEFAULT} and ${MAX_CHARS_CARD_NICKNAME} characters, or leave blank to remove.`}
        label={CardInputLabels.CARD_ALIAS}
        maxLength={MAX_CHARS_CARD_NICKNAME}
        name={CardInputFieldNames.CARD_ALIAS}
      />
    </div>
  );
}

import { CardDialogSuccessStates, useCardStore } from '@/store';

import { useCardShowSuccessDialog } from '../../hooks/use-card-show-success-dialog';

export function useUpdateCardNicknameSuccess() {
  const showSuccessDialog = useCardShowSuccessDialog();

  const {
    setIs3dsIframeOpen,
    setIsEditingCard,
    setIsEditingNickname,
    setIsViewCardOpen,
  } = useCardStore().use.actions();

  return function updateCardNicknameSuccessCallback() {
    setIs3dsIframeOpen(false);
    setIsViewCardOpen(false);
    setIsEditingNickname(false);
    setIsEditingCard(false);
    setIsEditingNickname(false);
    setIsEditingCard(false);
    showSuccessDialog(CardDialogSuccessStates.UPDATE_NICKNAME);
  };
}

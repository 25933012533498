export enum ButtonVariants {
  BRANDED = 'branded',
  DESTRUCTIVE = 'destructive',
  NEUTRAL = 'neutral',
}

export enum ButtonSizes {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum ButtonLevels {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

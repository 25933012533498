import {
  AddCardForm,
  ThreeDsIframe,
  endpoints,
  useFinalizeCard,
} from '@/features/card-management';
import { useCardStore } from '@/store';

import styles from './add-card.module.css';
import { useAddCardFinalize } from './use-add-card-finalize';
import { useAddCardFinalizeError } from './use-add-card-finalize-error';
import { useAddCardFinalizeSuccess } from './use-add-card-finalize-success';

export function AddCard() {
  const finalizeCard = useFinalizeCard({
    onError: useAddCardFinalizeError(),
    onSuccess: useAddCardFinalizeSuccess(),
  });

  const is3dsIframeOpen = useCardStore().use.is3dsIframeOpen();
  const threeDSecureId = useCardStore().use.threeDSecureId();

  const iframeSrc = `${import.meta.env.VITE_REACT_APP_BAAS_API_URL}${endpoints.BANK_CARDS_3DS_AUTHENTICATE_INIT(threeDSecureId)}`;

  useAddCardFinalize(finalizeCard);

  return (
    <div className={styles['add-card']}>
      {is3dsIframeOpen && threeDSecureId ? (
        <ThreeDsIframe src={iframeSrc} />
      ) : (
        <AddCardForm />
      )}
    </div>
  );
}

import { Portal } from '@radix-ui/react-dialog';
import { Content, Overlay } from '@radix-ui/react-dialog';
import { isValidElement } from 'react';
import { useId } from 'react';

import { DialogContentBody } from './dialog-content-body';
import { DialogContentFooter } from './dialog-content-footer';
import { DialogContentHeader } from './dialog-content-header';
import { DialogHeader } from './dialog-header';
import styles from './dialog.module.css';

type TDialogContentProps = {
  ariaLabel?: string;
  body?: React.ReactNode;
  description?: string;
  footer?: React.ReactNode;
  hasCloseIcon?: boolean;
  intro?: React.ReactNode;
  title?: string;
};

export function DialogContent({
  ariaLabel,
  body,
  description,
  footer,
  hasCloseIcon,
  intro,
  title,
}: TDialogContentProps) {
  const showHeader = !!description || !!title || hasCloseIcon || !!intro;
  const visuallyHidden = !!description && !title && !intro && !hasCloseIcon;
  const id = useId();
  const labelId = title || id;
  const describeId = description || id;
  return (
    <Portal>
      <Overlay className={styles['dialog-overlay']} role="presentation" />
      <Content
        aria-describedby={describeId}
        aria-label={ariaLabel}
        aria-labelledby={labelId}
        aria-modal="true"
        className={styles['dialog-inner']}
      >
        {showHeader && (
          <DialogHeader visuallyHidden={visuallyHidden}>
            <DialogContentHeader
              description={description}
              hasCloseIcon={hasCloseIcon}
              intro={intro}
              title={title}
            />
          </DialogHeader>
        )}
        {isValidElement(body) && <DialogContentBody body={body} />}
        {isValidElement(footer) && <DialogContentFooter footer={footer} />}
      </Content>
    </Portal>
  );
}

import {
  CloseIcon,
  PaidIcon,
  PendingIcon,
  RefundIcon,
} from '@/components/icons';

import { ViewOrderStatus } from '../enums';

import { Styled } from './styles';

interface IOrderStatusCardProps {
  orderStatus: `${ViewOrderStatus}`;
}

export const OrderStatusCard = ({ orderStatus }: IOrderStatusCardProps) => {
  const getStatusIcon = (status: `${ViewOrderStatus}`) => {
    if (status === ViewOrderStatus.PAID) {
      return <PaidIcon />;
    } else if (status === ViewOrderStatus.CANCELLED) {
      return (
        <CloseIcon color={'var(--colors-onErrorLow)'} height={17} width={17} />
      );
    } else if (
      status === ViewOrderStatus.REFUNDED ||
      status === ViewOrderStatus.PARTIALLY_REFUNDED
    ) {
      return <RefundIcon />;
    } else {
      return <PendingIcon color={'var(--colors-onSuccessLow)'} />;
    }
  };

  return (
    <Styled.OrderStatusWrapper $orderStatus={orderStatus}>
      {getStatusIcon(orderStatus)}

      {orderStatus}
    </Styled.OrderStatusWrapper>
  );
};

import type { OrderTypes } from '@/features/activity/types';

export const sortOrdersByDate = (
  orders?: OrderTypes.TOrderInfo[]
): OrderTypes.TOrderInfo[] | undefined => {
  return !orders || orders.length === 0
    ? undefined
    : orders.sort((a, b) => {
        const dateA = new Date(a.created_at.iso);
        const dateB = new Date(b.created_at.iso);
        return dateB.getTime() - dateA.getTime();
      });
};

import { useAuth } from 'react-oidc-context';

import { EventPriority } from '@/features/monitoring';
import { RequestMethod, request } from '@/services';

import { endpoints } from '../endpoints';
import {
  PaymentMethodEndpointKeys,
  paymentMethodTransactionKeys,
} from '../query-keys';

import type { TUpdateCardResponse } from './use-update-card-types';
import type { PaymentMethodTypes } from '../../types';

export function useUpdateCardRequest(cardId: string) {
  const auth = useAuth();

  return async function updateCardRequest(
    payload: PaymentMethodTypes.TUpdateCard3dsRequest
  ) {
    return await request<TUpdateCardResponse>(
      endpoints.BANK_CARDS_BY_ID(cardId),
      auth,
      {
        data: payload,
        method: RequestMethod.PUT,
      },
      {
        priority: EventPriority.P2,
        requestKey: PaymentMethodEndpointKeys.BANK_CARD_UPDATE_3DS,
        transaction: paymentMethodTransactionKeys.bankCardUpdate3DS,
      }
    );
  };
}

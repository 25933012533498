import { useInfiniteQuery } from '@tanstack/react-query';
import flow from 'lodash.flow';
import { useAuth } from 'react-oidc-context';

import { ordersQueryKeys } from './query-keys';
import { getOrdersRequest } from './use-get-orders-request';
import { select } from './use-get-orders-selectors';

import type { OrderServiceTypes } from './typings';
import type { TGetOrdersArgs } from './use-get-orders-types';
import type { OrderTypes } from '../types';
import type { AxiosError } from 'axios';

export function useGetOrders({ params }: TGetOrdersArgs) {
  const auth = useAuth();

  return useInfiniteQuery<
    OrderServiceTypes.TOrdersList,
    AxiosError,
    OrderTypes.TOrdersList,
    unknown[],
    string | undefined
  >({
    enabled: auth.isAuthenticated,
    getNextPageParam: (lastPage) =>
      !!lastPage.next_key && lastPage.next_key !== ''
        ? lastPage.next_key
        : undefined,
    initialPageParam: undefined,
    queryFn: ({ pageParam }) => getOrdersRequest({ auth, pageParam, params }),
    queryKey: ordersQueryKeys.orders(params),
    select: flow(select.filterOrders, select.formatOrders),
  });
}

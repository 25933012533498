import { getPayments } from './get-payments';
import { orderGet } from './order-get';
import { orderHas } from './order-has';

import type { OrderTypes } from '../types';
import type { V2QrTypes } from '@/services';

export const getOrderUtils = {
  cleanOrder(order?: OrderTypes.TPartialOrderInfo) {
    return !!order
      ? {
          amount: order.amount,
          created_at: order.created_at,
          hasMyColes: order.hasMyColes,
          hasRefund: order.hasRefund,
          merchantId: order.merchantId,
          merchantLocation: order.merchantLocation,
          merchantName: order.merchantName,
          orderId: order.order_id,
          orderReference: order.order_reference,
          orderStatus: order.orderStatus,
          paymentContext: order.payment_context,
          payments: order.payments,
        }
      : order;
  },

  getMerchantDetails(order?: V2QrTypes.TOrderV2) {
    const merchant = order?.merchant;
    return {
      ...order,
      ...(!!merchant && orderGet.merchantDetails(merchant)),
    };
  },

  getMerchantLocation(order?: OrderTypes.TPartialOrderInfo) {
    const location = order?.merchant?.location;
    return {
      ...order,
      ...(!!location && {
        merchantLocation: orderGet.merchantLocation(location),
      }),
    };
  },

  getOrderAmount(order?: OrderTypes.TPartialOrderInfo) {
    return {
      ...order,
      amount: orderGet.orderAmount(order?.payments, order?.status),
    };
  },

  getOrderDate(order: OrderTypes.TPartialOrderInfo) {
    const date = order?.created_datetime;
    return {
      ...order,
      created_at: orderGet.orderDate(date),
    };
  },

  getOrderStatus(order?: OrderTypes.TPartialOrderInfo) {
    const status = order?.status;
    const hasRefund = order?.hasRefund;
    const amount = order?.amount;
    return {
      ...order,
      orderStatus: orderGet.orderStatus(status, amount, hasRefund),
    };
  },

  hasMycoles(order?: V2QrTypes.TOrderV2) {
    return {
      ...order,
      hasMyColes: orderHas.myColes(order?.non_payment_instruments),
    };
  },

  hasRefund(order?: OrderTypes.TPartialOrderInfo) {
    return {
      ...order,
      hasRefund: orderHas.refunds(order?.payments),
    };
  },

  transformPayments(order?: V2QrTypes.TOrderV2) {
    return {
      ...order,
      ...(!!order?.payments && {
        payments: getPayments(order?.payments, order?.status),
      }),
    };
  },
};

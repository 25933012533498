import { Breakpoints } from '@/config/breakpoints';
import { getBankCardLabel } from '@/features/card-management';
import { useWindowSize } from '@/hooks';

import { CardStatusPill } from '../card-status-pill';
import { PaymentCard } from '../payment-card/payment-card';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';

interface IPaymentCardListItemProps {
  card: PaymentMethodTypes.TBankCard;
  onClick?: () => void;
  selected?: boolean;
}

export const PaymentCardListItem = ({
  card,
  onClick,
  selected,
}: IPaymentCardListItemProps) => {
  const windowSize = useWindowSize();
  const paymentCardSize =
    !!windowSize?.width && windowSize.width > Breakpoints.xl ? 'md' : 'sm';

  const isDefault = !!card.is_default_pay;
  const hasPill = isDefault || !card.is_verified;

  const cardName = card.alias || card.data.issuer_name || '';
  const maskedCardNumber = `••• ${card.data.visualization.last_four_digits}`;

  return (
    <Styled.CardItem
      $hasPill={hasPill}
      $isUnavailable={!!card?.is_invalid}
      aria-label={getBankCardLabel(card, isDefault)}
      onClick={onClick}
      selected={selected}
    >
      <Styled.CardItemContent aria-hidden={'true'}>
        <PaymentCard
          cardType={card.data.type}
          schemes={card.data.schemes}
          size={paymentCardSize}
        />

        <Styled.CardItemContentTextContainer>
          <Styled.CardItemDetails>
            <Styled.CardItemName data-testid={'card-name'}>
              {cardName}
            </Styled.CardItemName>

            <Styled.CardItemNumber data-testid={'card-number'}>
              {maskedCardNumber}
            </Styled.CardItemNumber>
          </Styled.CardItemDetails>

          {hasPill && (
            <CardStatusPill
              isDefault={card.is_default_pay}
              status={card.status}
            />
          )}
        </Styled.CardItemContentTextContainer>
      </Styled.CardItemContent>
    </Styled.CardItem>
  );
};

import { cva } from 'class-variance-authority';

import styles from './button.module.css';
export const buttonVariants = cva(styles.button, {
  compoundVariants: [
    {
      className: styles['branded-secondary'],
      level: 'secondary',
      variant: 'branded',
    },
    {
      className: styles['branded-tertiary'],
      level: 'tertiary',
      variant: 'branded',
    },
    {
      className: styles['destructive-secondary'],
      level: 'secondary',
      variant: 'destructive',
    },
    {
      className: styles['destructive-tertiary'],
      level: 'tertiary',
      variant: 'destructive',
    },
  ],
  defaultVariants: {
    level: 'primary',
    size: 'small',
  },
  variants: {
    level: {
      primary: styles.primary,
      secondary: styles.secondary,
      tertiary: styles.tertiary,
    },
    size: {
      large: styles.large,
      medium: styles.medium,
      small: styles.small,
    },
    variant: {
      branded: styles.branded,
      destructive: styles.destructive,
      neutral: styles.neutral,
    },
  },
});

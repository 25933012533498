import { PaymentMethodEnums } from '@/features/card-management/enums';

import type { PaymentMethodTypes } from '@/features/card-management/types';

export const cardIs = {
  expired(card: PaymentMethodTypes.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.EXPIRED;
  },

  invalid(card: PaymentMethodTypes.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.INVALID;
  },

  unavailable(card: PaymentMethodTypes.TBankCard) {
    return this.invalid(card) || this.expired(card);
  },

  validated(card: PaymentMethodTypes.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.VALIDATED;
  },

  verified(card: PaymentMethodTypes.TBankCard) {
    return card?.status === PaymentMethodEnums.BankCardStatus.VERIFIED;
  },
};

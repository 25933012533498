import { Pill, PillVariants } from '@b707/ponyta';

import { getPillText } from '@/features/card-management';
// importing instead from @/features/card-management below results in a circular dependency when running the tests ¯\_(ツ)_/¯
import { PaymentMethodEnums } from '@/features/card-management/enums/payment-method-enums';

import type { TCardPillProps, TPillStatus } from './card-status-pill-types';

const pillVariants = {
  [PaymentMethodEnums.BankCardStatus.VERIFIED]: PillVariants.BRAND1,
  [PaymentMethodEnums.BankCardStatus.INVALID]: PillVariants.ERROR,
  [PaymentMethodEnums.BankCardStatus.EXPIRED]: PillVariants.WARNING,
  [PaymentMethodEnums.BankCardStatus.VALIDATED]: PillVariants.WARNING,
};

const getPillVariant = (
  status: TPillStatus,
  isDefault: boolean
): PillVariants => {
  if (status === PaymentMethodEnums.BankCardStatus.EXPIRED) {
    return PillVariants.ERROR;
  } else if (isDefault) {
    return PillVariants.GENERIC;
  }
  return status ? pillVariants[`${status}`] : PillVariants.ERROR;
};

export function CardStatusPill({
  isDefault = false,
  size = 'xs',
  status,
}: TCardPillProps) {
  return (
    <Pill
      rootCSS={{
        borderRadius: '120px',
        justifyContent: 'center',
        width: 'fit-content',
      }}
      size={size}
      variant={getPillVariant(status, isDefault)}
    >
      {getPillText(status, isDefault)}
    </Pill>
  );
}

import { useCardStore } from '@/store';

import type { TAuthorizeCardResponse } from './use-authorize-card-types';

export function useAuthorizeCardSuccess() {
  const selectedCard = useCardStore().use.selectedCard();
  const { setFinalizePayload, setIs3dsIframeOpen, setThreeDSecureId } =
    useCardStore().use.actions();

  return function addCardAuthorizeSuccessCallback(
    data: TAuthorizeCardResponse
  ) {
    setThreeDSecureId(data?.three_d_secure?.id);
    setFinalizePayload({
      authorization_id: data?.three_d_secure?.id,
      card_id: data?.card_id,
      isDefault: !!selectedCard?.is_default_pay,
    });
    setIs3dsIframeOpen(true);
  };
}

import { AddIcon } from '@/components/icons';
import { useAnalytics } from '@/hooks';
import { useCardStore } from '@/store';

import styles from './cards-panel-button.module.css';
import { Styled } from './styles';

type TBankCardsAddButton = {
  isShowAddButton: boolean;
};

export function CardsPanelButton({ isShowAddButton }: TBankCardsAddButton) {
  const { track } = useAnalytics();

  const isAddCardOpen = useCardStore().use.isAddCardOpen();
  const setIsAddCardOpen = useCardStore().use.actions().setIsAddCardOpen;

  const handleAddCardOpen = () => {
    if (!isAddCardOpen) {
      track('Add Card Started');
      setIsAddCardOpen(true);
    }
  };

  return (
    <>
      {isShowAddButton && (
        <Styled.AddBtn
          aria-label="Add Payment card"
          level="tertiary"
          onClick={handleAddCardOpen}
          variant="branded"
        >
          <div className={styles['panel-card-button-wrapper']}>
            <AddIcon /> Add
          </div>
        </Styled.AddBtn>
      )}
    </>
  );
}

import { min } from 'date-fns';

import { V2QrEnums } from '@/services';

import { getCard } from './get-card';

import type { OrderTypes } from '@/features/activity/types';
import type { V2QrTypes } from '@/services';

export function getPayments(
  payments: V2QrTypes.TPaymentV2[],
  status: `${V2QrEnums.OrderStatusV2}`
): OrderTypes.TPaymentInfo[] {
  const allPayments = payments.map((payment) => {
    return {
      amount: getTotalPaymentAmount(payment.transactions),
      date: getPaymentDate(payment),
      hasRefund: payment.is_refund,
      id: payment.payment_id,
      isPending: payment.transactions.some(
        (transaction) =>
          transaction.status === V2QrEnums.TransactionStatus.PENDING
      ),
      status: payment.status,
      transactions: payment.transactions
        .filter((transaction) => isValidTransaction(transaction, status))
        .map((transaction) => ({ ...transaction, card: getCard(transaction) })),
    };
  });

  return allPayments.filter(
    (payment) =>
      payment.transactions.length > 0 &&
      payment.status !== V2QrEnums.PaymentStatus.DECLINED
  );
}

function isValidTransaction(
  transaction: V2QrTypes.TTransaction,
  orderStatus?: `${V2QrEnums.OrderStatusV2}`
): boolean {
  if (orderStatus === V2QrEnums.OrderStatusV2.ABORTED) {
    return transaction.status !== V2QrEnums.TransactionStatus.PENDING;
  }
  const { amount, status } = transaction;
  return (
    !(
      status === V2QrEnums.TransactionStatus.FAILED ||
      status === V2QrEnums.TransactionStatus.DECLINED
    ) &&
    (amount.value !== '0.00' || status === V2QrEnums.TransactionStatus.PENDING)
  );
}

export function getAmount(amount: V2QrTypes.TAmountEntry | string): number {
  let parsedAmount = 0;
  if (amount instanceof Object && 'value' in amount) {
    parsedAmount = parseFloat(amount.value);
  } else {
    parsedAmount = parseFloat(amount);
  }
  return isNaN(parsedAmount) ? 0 : parsedAmount;
}

export function getTotalPaymentAmount(
  transactions: V2QrTypes.TTransaction[]
): number {
  return transactions.reduce((total, transaction) => {
    return total + getAmount(transaction.amount);
  }, 0);
}

export function getPaymentDate(payment: V2QrTypes.TPaymentV2): Date {
  return !hasProcessedTransactions(payment)
    ? new Date(payment.created_datetime)
    : getEarliestProcessedDate(payment);
}

export function hasProcessedTransactions(payment: V2QrTypes.TPaymentV2) {
  return payment.transactions.some(
    (transaction) =>
      transaction.card_type === V2QrEnums.SchemePaymentMethodType.CARD &&
      !!transaction.processed
  );
}

export function getEarliestProcessedDate(payment: V2QrTypes.TPaymentV2): Date {
  const processedDates = [];
  for (const transaction of payment.transactions) {
    if (
      transaction.card_type === V2QrEnums.SchemePaymentMethodType.CARD &&
      !!transaction.processed
    ) {
      processedDates.push(new Date(transaction.processed));
    }
  }
  return min(processedDates);
}

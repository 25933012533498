import { ProfileAvatar } from '@/components';

import { useGetMerchantLogo } from '../../services/use-get-merchant-logo';

type TMerchantLogoProps = {
  merchantId: string;
  merchantName?: string;
  size?: 'lg' | 'sm';
};

export function MerchantLogo({
  merchantId,
  merchantName,
  size = 'sm',
}: TMerchantLogoProps) {
  const { data } = useGetMerchantLogo({ merchantId });

  return (
    <ProfileAvatar
      alt={`${merchantName} logo`}
      name={merchantName}
      size={size}
      src={data}
    />
  );
}

import type { TGiftCard as GiftCard } from '../services/gift-cards-services-types';
import type { BaasComponentsTypes, BaasTypes } from '@/services';

export namespace GiftCardTypes {
  export enum GiftCardStatus {
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    USED = 'USED',
  }
  export type TGiftCardStatus =
    BaasComponentsTypes['schemas']['GiftCardStatus'];

  export type TGiftCard = Omit<GiftCard, 'status'> & {
    isExpired?: boolean;
    isInvalid?: boolean;
    isUsed?: boolean;
    status: GiftCardStatus;
  };

  export type TGetGiftCardsResponse = Omit<
    BaasTypes['/v1/payment-methods/gift-cards']['get']['responses'][200]['content']['application/json'],
    'cards'
  > & {
    cards: TGiftCard[];
  };
}

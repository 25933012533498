import { useHasUserConsentedTerms } from '@/features/profile';
import { isCardErrorTnc } from '@/utils';

import { CardsPanelButton } from '../cards-panel-button/cards-panel-button';
import { LoadingCard } from '../loading-card';

import { Styled } from './styles';

import type { PaymentMethodTypes } from '@/features/card-management';
import type { TBaasErrorResponse } from '@/services';
import type { UseQueryResult } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';

interface ICardsPanel<
  TQueryFnData = PaymentMethodTypes.TPaymentMethodsBankCardsResponse,
  TQueryError = TBaasErrorResponse,
> extends PropsWithChildren {
  query: UseQueryResult<TQueryFnData, TQueryError>;
  title: string;
}

export function CardsPanel({ children, query, title }: ICardsPanel) {
  const consentedTerms = useHasUserConsentedTerms();

  const isCardsQueryLoading =
    !consentedTerms ||
    isCardErrorTnc(query?.error, query?.isLoading, query?.isError);

  const isExceedMaxNumberOfCards = !!query?.data?.isExceedMaxNumberOfCards;
  const isShowAddButton = query.isSuccess && !isExceedMaxNumberOfCards;

  return (
    <Styled.PanelWrapper>
      <Styled.PanelTitleWrapper>
        <Styled.PanelTitle>{title}</Styled.PanelTitle>

        <CardsPanelButton isShowAddButton={isShowAddButton} />
      </Styled.PanelTitleWrapper>

      {isCardsQueryLoading ? <LoadingCard /> : children}
    </Styled.PanelWrapper>
  );
}

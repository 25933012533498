import { ErrorIcon } from '@/components/icons';

import styles from './orders-table-empty.module.css';

export function OrdersTableError() {
  return (
    <div className={styles['container']} role="alert">
      <ErrorIcon height={62} width={62} />
      <div className={styles['message']}>
        <h1>Error loading transaction data</h1>
        <p>
          We’re sorry, there was an error loading your flypay transactions,
          please refresh the page to try again.
        </p>
      </div>
    </div>
  );
}

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthProvider } from 'react-oidc-context';

import { ErrorFallback } from '@/components';
import { hasAuthAccessToken, trackSegmentRequestError } from '@/services';
import { GlobalStyles, getDefaultTheme } from '@/theme';

import { authConfig } from './config';
import { ThemeWrapper } from './contexts/theme';
import { AppRoutes } from './routes';
import { CardStoreProvider } from './store';

import type { AxiosError } from 'axios';
import './theme/theme-tokens.css';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      networkMode: 'always',
      retry: false,
    },
    queries: {
      enabled: hasAuthAccessToken(),
      networkMode: 'always',
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Number.POSITIVE_INFINITY,
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      trackSegmentRequestError(queryClient, error as AxiosError);
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      trackSegmentRequestError(queryClient, error as AxiosError);
    },
  }),
});

export const App = () => {
  const initialTheme = getDefaultTheme();
  // Prevents iframe injection
  useEffect(() => {
    if (window.top !== window.self && window.top) {
      window.top.location = window.self.location;
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles theme={initialTheme} />
      <ReactQueryDevtools initialIsOpen={false} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider
          {...authConfig.oidc}
          redirect_uri={`${window.location.origin}/`}
        >
          <ThemeWrapper initialTheme={initialTheme}>
            <CardStoreProvider>
              <AppRoutes />
            </CardStoreProvider>
          </ThemeWrapper>
        </AuthProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */

import { useStore } from 'zustand';

import type { StoreApi, UseBoundStore } from 'zustand';

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

// NOTE: this is for zustand stores created via the "create" method
export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  const store = _store as WithSelectors<typeof _store>;

  store.use = {};

  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => {
      return store((s) => s[k as keyof typeof s]); // eslint-disable-line @typescript-eslint/no-explicit-any
    };
  }

  return store;
};

// NOTE: this is for zustand stores created via the "createStore" method
export const createStoreSelectors = <S extends StoreApi<object>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>;

  if (!!store) {
    store.use = {};

    for (const k of Object.keys(store.getState())) {
      (store.use as any)[k] = () => {
        // eslint-disable-next-line
        return useStore(_store, (s) => s[k as keyof typeof s]);
      };
    }
  }

  return store;
};
